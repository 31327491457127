import { takeLatest, spawn, put } from 'redux-saga/effects';

/* Project */
import apiRequest, { apiSuccess } from 'utils/api';
import { getStateFromApi, getStateFromApiSuccess, setErrorMessage } from './app.actions';

const clearStateIfIsUnauthorized = (statusCode) => {
  if (statusCode === 401) {
    localStorage.clear();
    window.location.replace(window.location.origin);
  }
};

const setToken = (token, type) => {
  if (token && type) {
    localStorage.setItem('user', JSON.stringify({ token, userType: type }));
  }
};

const setCsrfToken = (csrfToken) => {
  if (csrfToken) {
    localStorage.setItem('csrfToken', csrfToken);
  }
};

function* reflectGetStateFromApi() {
  // eslint-disable-next-line complexity
  yield takeLatest(getStateFromApi, function* fetchState() {
    try {
      const response = yield apiRequest('api/v1/', { method: 'get' });
      clearStateIfIsUnauthorized(response.statusCode);
      if (response.token && response.user && response.user.type) {
        setToken(response.token, response.user.type); // Incluye el rol
      }

      setCsrfToken(response.csrfToken);

      if (response.error) {
        throw new Error('Falló la carga inicial de información.');
      }

      return yield put(
        getStateFromApiSuccess({
          user: response.user,
          signedByGoogle: response.signedByGoogle,
          displayLogin: response.displayLogin,
        }),
      );
    } catch (e) {
      return yield put(
        apiSuccess(setErrorMessage, { msg: 'Falló la carga inicial de información.' }),
      );
    }
  });
}

export default function* AppSaga() {
  yield spawn(reflectGetStateFromApi);
}
