import { createAction } from '@reduxjs/toolkit';
import OperatorRequestsTypes from 'content/features/operator-requests/operatorRequests.types';

export const fetchOperatorRequestsList = createAction(OperatorRequestsTypes.FETCH_REQUESTS);

export const fetchOperatorRequestsListFail = createAction(
  OperatorRequestsTypes.FETCH_REQUESTS_FAIL,
);

export const fetchOperatorRequestsListSuccess = createAction(
  OperatorRequestsTypes.FETCH_REQUESTS_SUCCESS,
);

export const setPagination = createAction(OperatorRequestsTypes.SET_PAGINATION, (payload) => ({
  payload,
}));

export const revealRowData = createAction(OperatorRequestsTypes.REVEAL_ROW_DATA, (payload) => ({
  payload,
}));

export const revealRowDataFail = createAction(OperatorRequestsTypes.REVEAL_ROW_DATA_FAIL);

export const revealRowDataSuccess = createAction(OperatorRequestsTypes.REVEAL_ROW_DATA_SUCCESS);

export const setControl = createAction(OperatorRequestsTypes.SET_CONTROL, (payload) => ({
  payload: {
    ...payload,
  },
}));

export const createRequestCheckboxesControls = createAction(
  OperatorRequestsTypes.CREATE_REQUEST_CHECKBOXES_CONTROLS,
  (payload) => ({ payload: [...payload] }),
);

export const toggleIndividualCheck = createAction(
  OperatorRequestsTypes.TOGGLE_INDIVIDUAL_CHECK,
  (payload) => ({ payload }),
);

export const toggleMasterCheck = createAction(
  OperatorRequestsTypes.TOGGLE_MASTER_CHECK,
  (payload) => ({ payload }),
);

export const hideRowData = createAction(OperatorRequestsTypes.HIDE_ROW_DATA, (payload) => ({
  payload,
}));

export const hideRowDataFail = createAction(OperatorRequestsTypes.HIDE_ROW_DATA_FAIL);

export const hideRowDataSuccess = createAction(OperatorRequestsTypes.HIDE_ROW_DATA_SUCCESS);

export const assignRequest = createAction(OperatorRequestsTypes.ASSIGN_REQUEST);

export const assignRequestFail = createAction(OperatorRequestsTypes.ASSIGN_REQUEST_FAIL);

export const assignRequestSuccess = createAction(OperatorRequestsTypes.ASSIGN_REQUEST_SUCCESS);

export const getInitialData = createAction(OperatorRequestsTypes.GET_INITIAL_DATA);

export const getInitialDataFail = createAction(OperatorRequestsTypes.GET_INITIAL_DATA_FAIL);

export const getInitialDataSuccess = createAction(OperatorRequestsTypes.GET_INITIAL_DATA_SUCCESS);

export const clearFilters = createAction(OperatorRequestsTypes.CLEAR_FILTERS);

export const getTableReport = createAction(OperatorRequestsTypes.GET_TABLE_REPORT);

export const getTableReportFail = createAction(OperatorRequestsTypes.GET_TABLE_REPORT_FAIL);

export const getTableReportSuccess = createAction(OperatorRequestsTypes.GET_TABLE_REPORT_SUCCESS);

export const getClientReport = createAction(OperatorRequestsTypes.GET_CLIENT_REPORT);

export const getClientReportFail = createAction(OperatorRequestsTypes.GET_CLIENT_REPORT_FAIL);

export const getClientReportSuccess = createAction(OperatorRequestsTypes.GET_CLIENT_REPORT_SUCCESS);

const OperatorRequestsActions = {
  fetchOperatorRequestsList,
  fetchOperatorRequestsListFail,
  fetchOperatorRequestsListSuccess,
  setPagination,
  revealRowData,
  revealRowDataFail,
  revealRowDataSuccess,
  setControl,
  createRequestCheckboxesControls,
  toggleIndividualCheck,
  toggleMasterCheck,
  assignRequest,
  assignRequestFail,
  assignRequestSuccess,
  hideRowData,
  hideRowDataFail,
  hideRowDataSuccess,
  getInitialData,
  getInitialDataFail,
  getInitialDataSuccess,
  clearFilters,
  getTableReport,
  getTableReportFail,
  getTableReportSuccess,
  getClientReport,
  getClientReportFail,
  getClientReportSuccess,
};

export default OperatorRequestsActions;
