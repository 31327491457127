const BeneficiaryRequestTypes = {
  FETCH_FORM_INITIAL_DATA: '[BeneficiaryRequest] FETCH_FORM_INITIAL_DATA',
  FETCH_FORM_INITIAL_DATA_SUCCESS: '[BeneficiaryRequest] FETCH_FORM_INITIAL_DATA_SUCCESS',
  FETCH_FORM_INITIAL_DATA_FAIL: '[BeneficiaryRequest] FETCH_FORM_INITIAL_DATA_FAIL',
  CREATE_BENEFICIARY_REQUEST: '[BeneficiaryRequest] CREATE_BENEFICIARY_REQUEST',
  CREATE_BENEFICIARY_REQUEST_SUCCESS: '[BeneficiaryRequest] CREATE_BENEFICIARY_REQUEST_SUCCESS',
  CREATE_BENEFICIARY_REQUEST_FAIL: '[BeneficiaryRequest] CREATE_BENEFICIARY_REQUEST_FAIL',
  SET_CONTROL: '[BeneficiaryRequest] SET_CONTROL',
  CLEAR_SCREEN_DATA: '[BeneficiaryRequest] CLEAR_SCREEN_DATA',
};
export default BeneficiaryRequestTypes;
