import { createReducer } from '@reduxjs/toolkit';
import {
  createUser,
  createUserFail,
  createUserSuccess,
  resetControls,
  setControl,
  setControls,
} from './register.actions';

const initialState = {
  controls: {
    name: '',
    email: '',
    password: '',
    repeatPassword: '',
  },
  loading: false,
  message: '',
};

const RegisterReducer = createReducer(initialState, (builder) => {
  builder
    // Manejo de controles del formulario
    .addCase(setControl, (state, { payload }) => {
      state.controls = { ...state.controls, ...payload };
    })
    .addCase(setControls, (state, { payload }) => {
      state.controls = { ...state.controls, ...payload };
    })
    .addCase(resetControls, (state) => {
      state.controls = { ...initialState.controls };
    })

    // Crear usuario
    .addCase(createUser, (state) => {
      state.loading = true;
    })
    .addCase(createUserSuccess, (state) => {
      state.controls = { ...initialState.controls };
      state.loading = false;
    })
    .addCase(createUserFail, (state) => {
      state.loading = false;
    });
});

export default RegisterReducer;
