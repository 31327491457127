import React, { useState } from 'react';

/* Material UI */
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

/* React Router */
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

/* Project */
import { verify2FA } from '../login.actions';
import { loginStyles } from '../../index';

/* Public */
const deloitteDesktop = `${window.location.origin}/images/deloitte-desktop-negro.png`;

function LoginMFA(props) {
  const [code, setCode] = useState('');
  const dispatch = useDispatch();
  const { loading, error, message, goToHome } = props;

  // Maneja el cambio en el campo de texto
  const onChangeText = (e) => {
    setCode(e.target.value); // Captura el código ingresado
  };

  // Maneja la verificación del código de seguridad
  const handleLogin = () => {
    const user = JSON.parse(localStorage.getItem('user')); // Obtiene el email del localStorage
    dispatch(verify2FA({ email: user.email, code })); // Despacha la acción de verificación

    // Puedes redirigir si la verificación es exitosa en la saga
  };

  return (
    <form onSubmit={(evt) => evt.preventDefault()}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100vh', backgroundColor: '#E8EAE9' }}>
        <Grid item md={4} lg={4} xs={12}>
          <Card sx={{ boxShadow: 0, borderRadius: 6, maxWidth: '500px', maxHeight: '640px' }}>
            <CardContent>
              <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={10} sx={{ mt: 4 }}>
                  <CardMedia
                    onClick={goToHome}
                    component="img"
                    style={{
                      cursor: 'pointer',
                      objectFit: 'contain',
                      maxWidth: '250px',
                      margin: 'auto',
                    }}
                    image={deloitteDesktop}
                    title="Deloitte"
                  />
                </Grid>
                <Grid item xs={10} textAlign="center" sx={{ mt: 1 }}>
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '24px',
                      fontWeight: 700,
                      lineHeight: '32.68px',
                    }}>
                    Código de seguridad
                  </Typography>
                </Grid>
                <Grid item xs={10} textAlign="left" sx={{ mt: 1 }}>
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '21.79px',
                    }}>
                    Ingresa el <strong>código de seguridad</strong> que fue enviado a tu correo
                    electrónico.
                  </Typography>
                </Grid>
                <Grid item xs={10} textAlign="center">
                  <TextField
                    sx={loginStyles.textfield}
                    autoFocus
                    label="Código de verificación"
                    id="code"
                    value={code} // Asocia el valor del estado
                    onChange={onChangeText} // Manejador de cambio
                    fullWidth
                  />
                </Grid>
                <Grid item xs={10} textAlign="left" sx={{ mt: 1 }}>
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '21.79px',
                    }}>
                    Si no puedes ver el código de seguridad, por favor revisa la carpeta de correos
                    no deseados o spam.
                    <br />
                    Si aún así no puedes encontrar el código de seguridad, por favor contáctanos a
                    través de: <a href="mailto:soporte@deloitte.com">soporte@deloitte.com</a>
                  </Typography>
                </Grid>
                {error && (
                  <Grid item xs={10} textAlign="center">
                    <Alert severity="error" sx={{ fontWeight: 'bold' }}>
                      {message}
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={10} textAlign="center">
                  <LoadingButton
                    data-testid="button-login"
                    onClick={handleLogin} // Manejador del clic
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: '5px', textTransform: 'none', fontWeight: 'bold' }}
                    loadingPosition="start"
                    size="large"
                    loading={loading} // Deshabilita si está cargando
                    disabled={loading || code === ''}>
                    Validar código
                  </LoadingButton>
                </Grid>
                <Grid item xs={10} textAlign="center" sx={{ mb: 4 }}>
                  <Typography variant="caption" color="primary">
                    <Link
                      to="/login"
                      style={{
                        textDecoration: 'none',
                        width: '100%',
                      }}>
                      <Button
                        sx={{ textTransform: 'none', fontWeight: 'bold' }}
                        fullWidth
                        variant="outlined"
                        size="large">
                        Volver
                      </Button>
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
}

export default LoginMFA;
