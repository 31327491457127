import React from 'react';
import { Grid, Skeleton } from '@mui/material';

function FieldSkeleton({ titleWidth = '150px', valueWidth = '261px' }) {
  return (
    <Grid container item xs={3} spacing={1}>
      <Grid item xs={12}>
        <Skeleton variant="rounded" width={titleWidth} height="12px" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" width={valueWidth} height="24px" />
      </Grid>
    </Grid>
  );
}

function TableRowSkeleton() {
  return (
    <>
      <Grid item xs={2}>
        <Skeleton variant="rounded" width="103px" height="20px" />
      </Grid>
      <Grid item xs={2}>
        <Skeleton variant="rounded" width=" 204px" height="20px" />
      </Grid>
      <Grid item xs={2} container justifyContent="center">
        <Skeleton variant="rounded" width="75px" height="20px" />
      </Grid>
      <Grid item xs={2} container justifyContent="center">
        <Skeleton variant="rounded" width="59px" height="20px" />
      </Grid>
      <Grid item xs={2} container justifyContent="center">
        <Skeleton variant="rounded" width="50px" height="20px" />
      </Grid>
      <Grid container item xs={2} sx={{ mb: 2 }} alignItems="center" justifyContent="center">
        <Skeleton variant="rounded" width="18px" height="18px" sx={{ mr: 1 }} />
        <Skeleton variant="rounded" width="20px" height="20px" />
      </Grid>
    </>
  );
}
function BeneficiaryRequestSkeleton() {
  return (
    <Grid container spacing={1} sx={{ mt: 2.5 }}>
      <Grid item xs={12} sx={{ mb: 2 }} container alignItems="center">
        <Skeleton variant="rounded" width="48px" height="48px" sx={{ mr: 1 }} />
        <Skeleton variant="rounded" width="266px" height="48px" />
      </Grid>
      <Grid item xs={12} sx={{ mb: 6 }}>
        <Skeleton variant="rounded" width="794px" height="22px" />
      </Grid>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Skeleton variant="rounded" width="147px" height="24px" />
      </Grid>
      <Grid item xs={12} container sx={{ mb: 8 }}>
        <FieldSkeleton titleWidth="91px" valueWidth="323px" />
      </Grid>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Skeleton variant="rounded" width="1240px" height="56px" />
      </Grid>
      <Grid item xs={12} sx={{ mb: 3 }} container>
        <TableRowSkeleton />
      </Grid>
      <Grid item xs={12} sx={{ mb: 3 }} container>
        <TableRowSkeleton />
      </Grid>
      <Grid item xs={12} sx={{ mb: 3 }} container>
        <TableRowSkeleton />
      </Grid>
      <Grid item xs={12} sx={{ mb: 3 }} container>
        <TableRowSkeleton />
      </Grid>
      <Grid item xs={12} sx={{ mb: 3 }} container>
        <TableRowSkeleton />
      </Grid>
      <Grid item xs={12} sx={{ mb: 3 }} container>
        <TableRowSkeleton />
      </Grid>
      <Grid item xs={12} sx={{ mb: 3 }} container>
        <TableRowSkeleton />
      </Grid>
      <Grid item xs={12} sx={{ mb: 3 }} container>
        <TableRowSkeleton />
      </Grid>
      <Grid item xs={12} sx={{ mb: 3 }} container>
        <TableRowSkeleton />
      </Grid>
      <Grid item xs={12} sx={{ mb: 3 }} container>
        <TableRowSkeleton />
      </Grid>
    </Grid>
  );
}

export default BeneficiaryRequestSkeleton;
