const OperatorRequestDetailTypes = {
  FETCH_REQUEST: '[OperatorRequestDetail] FETCH_REQUEST',
  FETCH_REQUEST_SUCCESS: '[OperatorRequestDetail] FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_FAIL: '[OperatorRequestDetail] FETCH_REQUEST_FAIL',
  SET_CONTROL: '[OperatorRequestDetail] SET_CONTROL',
  OBJECT_REQUEST: '[OperatorRequestDetail] OBJECT_REQUEST',
  OBJECT_REQUEST_SUCCESS: '[OperatorRequestDetail] OBJECT_REQUEST_SUCCESS',
  OBJECT_REQUEST_FAIL: '[OperatorRequestDetail] OBJECT_REQUEST_FAIL',
  ACCEPT_REQUEST: '[OperatorRequestDetail] ACCEPT_REQUEST',
  ACCEPT_REQUEST_SUCCESS: '[OperatorRequestDetail] ACCEPT_REQUEST_SUCCESS',
  ACCEPT_REQUEST_FAIL: '[OperatorRequestDetail] ACCEPT_REQUEST_FAIL',
  REJECT_REQUEST: '[OperatorRequestDetail] REJECT_REQUEST',
  REJECT_REQUEST_SUCCESS: '[OperatorRequestDetail] REJECT_REQUEST_SUCCESS',
  REJECT_REQUEST_FAIL: '[OperatorRequestDetail] REJECT_REQUEST_FAIL',
  PAY_REQUEST: '[OperatorRequestDetail] PAY_REQUEST',
  PAY_REQUEST_SUCCESS: '[OperatorRequestDetail] PAY_REQUEST_SUCCESS',
  PAY_REQUEST_FAIL: '[OperatorRequestDetail] PAY_REQUEST_FAIL',
  TOGGLE_CHECK: '[OperatorRequestDetail] TOOGLE_CHECK',
  CLEAR_SCREEN_DATA: '[OperatorRequestDetail] CLEAR_SCREEN_DATA',
};
export default OperatorRequestDetailTypes;
