import { spawn, takeLatest, put } from 'redux-saga/effects';

import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import apiRequest from 'utils/api';
import { setLoader } from 'content/app.actions';
import {
  fetchFormInitialData,
  fetchFormInitialDataFail,
  fetchFormInitialDataSuccess,
  createBeneficiaryRequest,
  createBeneficiaryRequestFail,
  createBeneficiaryRequestSuccess,
} from './beneficiaryRequest.actions';

function* list() {
  yield takeLatest(fetchFormInitialData, function* fetchFormInitialDataSaga() {
    const response = yield apiRequest('api/v1/conciliation-request/beneficiary/form-selects-data', {
      method: 'get',
    });
    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(fetchFormInitialDataFail());
    } else {
      defaultSuccessToast({ message: 'Datos cargados exitosamente' });
      yield put(fetchFormInitialDataSuccess(response));
    }
  });
}

function* create() {
  yield takeLatest(createBeneficiaryRequest, function* createBeneficiaryRequestSaga(action) {
    yield put(setLoader('createBeneficiaryRequestButtonId', true));
    const { frontIdentityDocument, backIdentityDocument, carInscriptionDocument, ...form } =
      action.payload;

    const formData = new FormData();
    formData.append('frontIdentityDocument', frontIdentityDocument);
    formData.append('backIdentityDocument', backIdentityDocument);
    formData.append('carInscriptionDocument', carInscriptionDocument);
    formData.append('form', JSON.stringify(form));

    const response = yield apiRequest(
      'api/v1/conciliation-request/beneficiary/request',
      { method: 'post', body: formData },
      false,
    );
    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(createBeneficiaryRequestFail());
    } else {
      defaultSuccessToast({ message: 'Solicitud creada exitosamente.' });
      yield put(createBeneficiaryRequestSuccess(response));
    }
    yield put(setLoader('createBeneficiaryRequestButtonId', false));
  });
}

export default function* BeneficiaryRequestSaga() {
  yield spawn(list);
  yield spawn(create);
}
