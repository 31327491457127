import React from 'react';

import { Grid, Typography } from '@mui/material';

function Section({ title, paragraph }) {
  return (
    <Grid item xs={12} mb={3}>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 700,
          lineHeight: '24px',
        }}>
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '24px',
        }}>
        {paragraph}
      </Typography>
    </Grid>
  );
}

export default Section;
