/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';

/* Extra */
import { Checkbox, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

/* Project */
import { borderColorFunction } from 'utils/helper';
import SectionTitle from 'content/shared/SectionTitle/SectionTitle';

function TermsAndConditions({ errors, setControl }) {
  const dispatch = useDispatch();

  const [localTermsAndConditions, setTermsAndConditions] = useState({
    terms1: false,
    terms2: false,
    terms3: false,
    terms4: false,
  });

  useEffect(() => {
    const allTermsAndConditions = Object.values(localTermsAndConditions).every((value) => value);

    dispatch(setControl({ name: 'termsAndConditions', value: allTermsAndConditions }));
  }, [localTermsAndConditions]);

  const defaultColor = '#12266F';

  const onChangeLocal = (key) => {
    setTermsAndConditions((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        mt: { md: 4, xs: 6 },
      }}>
      <Grid item xs={12}>
        <SectionTitle title="Declaraciones" />
      </Grid>
      <Grid item xs={12} mt={2}>
        <Typography
          variant="body2"
          fontWeight="300"
          sx={{
            color: borderColorFunction(
              !localTermsAndConditions.terms1 && errors.termsAndConditions,
              defaultColor,
            ),
            display: 'flex',
            alignItems: 'center',
          }}>
          <Checkbox
            sx={{
              pl: 0,
            }}
            checked={localTermsAndConditions.terms1}
            onChange={() => onChangeLocal('terms1')}
            id="terms1"
          />
          Declaro que soy Beneficiario en los términos establecidos en la definición I.b. de la
          Conciliación.
        </Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Typography
          variant="body2"
          fontWeight="300"
          sx={{
            color: borderColorFunction(
              !localTermsAndConditions.terms2 && errors.termsAndConditions,
              defaultColor,
            ),
            display: 'flex',
            alignItems: 'center',
          }}>
          <Checkbox
            sx={{
              pl: 0,
            }}
            checked={localTermsAndConditions.terms2}
            onChange={() => onChangeLocal('terms2')}
            id="terms2"
          />
          La información aportada en esta Solicitada es verdadera y correcta, conforme a mi mejor
          entendimiento. La Solicitud podrá ser auditada, revisada y validada mediante el uso de
          toda la información disponible.
        </Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Typography
          variant="body2"
          fontWeight="300"
          sx={{
            color: borderColorFunction(
              !localTermsAndConditions.terms3 && errors.termsAndConditions,
              defaultColor,
            ),
            display: 'flex',
            alignItems: 'center',
          }}>
          <Checkbox
            sx={{
              pl: 0,
            }}
            checked={localTermsAndConditions.terms3}
            onChange={() => onChangeLocal('terms3')}
            id="terms3"
          />
          Comprendo que el envío de esta solicitud constituye consentimiento para los efectos del
          Artículo 10 de la Ley N° 19.628 sobre Protección de la Vida Privada, exclusivamente para
          fines de (i) supervisar el cumplimiento de los deberes del Administrador, (ii) efectuar
          revisiones de cumplimiento (compliance), (iii) responder solicitudes de información
          formuladas por autoridades administrativas o tribunales nacionales o extranjeros y (iv) en
          general, el cumplimiento de toda obligación legal.
        </Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Typography
          variant="body2"
          fontWeight="300"
          sx={{
            color: borderColorFunction(
              !localTermsAndConditions.terms4 && errors.termsAndConditions,
              defaultColor,
            ),
            display: 'flex',
            alignItems: 'center',
          }}>
          <Checkbox
            sx={{
              pl: 0,
            }}
            checked={localTermsAndConditions.terms4}
            onChange={() => onChangeLocal('terms4')}
            id="terms4"
          />
          Comprendo y acepto que el envío de esta solicitud constituye una autorización para los
          efectos del artículo 154 del D.F.L. N° 3 que establece la Ley General de Bancos,
          exclusivamente (i) para efectos de informar la cuenta bancaria, su titular y la
          circunstancia de haberse efectuado un pago a ella en conformidad con la Conciliación
          alcanzada en procedimiento CIP-6-2020 seguido ante el H. Tribunal de Defensa de la Libre
          Competencia y (ii) para los fines indicados en el párrafo anterior.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default TermsAndConditions;
