import React from 'react';
import CustomTypographyView from 'content/shared/Views/CustomTypographyView';

function VehicleView({ vehicleVin, vehicleLicensePlate, vehicleBrand, vehicleModel, vehicleYear }) {
  return (
    <>
      <CustomTypographyView label="VIN o número de vehículo" text={vehicleVin} />
      <CustomTypographyView label="Patente" text={vehicleLicensePlate} />
      <CustomTypographyView label="Marca" text={vehicleBrand} />
      <CustomTypographyView label="Modelo" text={vehicleModel} />
      <CustomTypographyView label="Año" text={vehicleYear} />
    </>
  );
}

export default VehicleView;
