import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

/* React Router */
import { useLocation, useNavigate } from 'react-router-dom';

/* Material UI */
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Button,
  Container,
  Divider,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
// Project
import { Logout } from '@mui/icons-material';
import navbarStyles from './navbar.styles';

const deloitteDesktop = `${window.location.origin}/images/deloitte-desktop.png`;
const deloitteMobile = `${window.location.origin}/images/deloitte-mobile.png`;

// eslint-disable-next-line complexity
const NavBar = forwardRef((props, ref) => {
  const { setOpenDrawer, handleLogout, user, displayLogin } = props;
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const location = useLocation();
  const isHome = location.pathname === '/';

  const navItems = isMobile
    ? []
    : [
        { id: 1, url: '/', name: 'Inicio' },
        { id: 2, url: '/#nextDates', name: 'Próximas Fechas' },
        { id: 3, url: '/#documents', name: 'Documentos' },
        { id: 4, url: '/#faq', name: 'Preguntas frecuentes' },
      ];

  const handleLink = (url) => {
    navigate(url);
    // setMobileOpen(false);
  };

  const buttons = (
    <>
      {navItems.map((item) => (
        <Button
          key={item.id}
          onClick={() => handleLink(item.url)}
          color="inherit"
          sx={{ textTransform: 'none', fontWeight: 'bold' }}>
          {item.name}
        </Button>
      ))}
    </>
  );

  // eslint-disable-next-line complexity
  function sessionOption() {
    if (user) {
      return (
        <>
          <Typography variant="subtitle" sx={navbarStyles.userWelcome} display={isMobile && 'none'}>
            ¡Bienvenido, {user.name}!
          </Typography>
          {!isMobile && (
            <Divider orientation="vertical" variant="middle" flexItem sx={navbarStyles.divider} />
          )}
          <Button
            onClick={() => handleLogout()}
            color="inherit"
            sx={{ textTransform: 'none', fontWeight: 'bold', fontSize: '12px' }}>
            <Logout sx={{ marginRight: 1 }} />
            Cerrar sesión
          </Button>
        </>
      );
    }

    if (!displayLogin) {
      return null;
    }

    return (
      <>
        <Button
          onClick={() => navigate('/login')}
          color="inherit"
          sx={{ textTransform: 'none', fontWeight: 'bold' }}>
          Iniciar sesión
        </Button>
        <Button
          onClick={() => navigate('/register')}
          color="inherit"
          sx={{ textTransform: 'none', fontWeight: 'bold' }}>
          Registrarse
        </Button>
      </>
    );
  }

  const customStyles = {
    paddingLeft: isHome || isMobile ? '' : '6rem',
    paddingRight: isHome || isMobile ? '' : '6rem',
    maxWidth: isHome ? '' : '100%',
  };
  // const customStyles = {};

  return (
    <nav>
      <AppBar
        position="fixed"
        sx={navbarStyles.navBar}
        style={{ paddingTop: !isHome ? 0 : '' }}
        ref={ref}>
        <Container sx={{ px: { sm: 1 } }} style={customStyles}>
          <Toolbar sx={{ px: { sm: 0 } }}>
            {user && (
              <IconButton
                data-testid="toolbar-menu-button"
                onClick={() => setOpenDrawer(true)}
                edge="start"
                sx={navbarStyles.menuButton}
                color="inherit"
                aria-label="menu">
                <MenuIcon />
              </IconButton>
            )}
            <IconButton onClick={() => navigate('/')}>
              <img
                style={{ height: isHome ? '40px' : '24px' }}
                src={isMobile ? deloitteMobile : deloitteDesktop}
                alt=""
              />
            </IconButton>
            <Typography variant="h6" sx={navbarStyles.title} />
            {buttons}
            {sessionOption()}
          </Toolbar>
        </Container>
      </AppBar>
    </nav>
  );
});

NavBar.propTypes = {
  setOpenDrawer: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  user: PropTypes.shape({}),
};

NavBar.defaultProps = {
  user: null,
};

export default NavBar;
