import { Alert, Grid, Hidden, MenuItem, TextField } from '@mui/material';
import React from 'react';

// eslint-disable-next-line complexity
function PaymentMethodForm({ errors, onChange, controls, initialData, amendment }) {
  const { accountPaymentMethods, accountBanks, accountTypes } = initialData;
  const {
    accountHolderName,
    accountHolderRut,
    accountType,
    accountHolderNumber,
    accountBank,
    accountPaymentMethod,
    rut,
  } = controls;

  const transferId = accountPaymentMethods.find(
    (method) => method.label === 'TRANSFERENCIA',
  )?.value;

  return (
    <>
      <Grid item md={3} xs={12}>
        <TextField
          id="accountPaymentMethod"
          name="accountPaymentMethod"
          fullWidth
          select
          label="Método de pago"
          error={!!errors.accountPaymentMethod[0]}
          helperText={errors.accountPaymentMethod[0]}
          onChange={onChange}
          value={accountPaymentMethod}>
          {accountPaymentMethods.map((method) => (
            <MenuItem key={method.value} value={method.value}>
              {method.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          id="accountHolderName"
          name="accountHolderName"
          label="Nombre titular de la cuenta"
          fullWidth
          variant="outlined"
          error={!!errors.accountHolderName[0]}
          helperText={errors.accountHolderName[0]}
          onChange={onChange}
          value={accountHolderName}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          id="accountHolderRut"
          name="accountHolderRut"
          label="RUT/RUN del titular"
          disabled={amendment}
          fullWidth
          variant="outlined"
          error={!!errors.accountHolderRut[0]}
          helperText={errors.accountHolderRut[0]}
          onChange={onChange}
          value={accountHolderRut}
        />
      </Grid>
      <Hidden smDown>
        <Grid item xs />
      </Hidden>
      {accountPaymentMethod === transferId && (
        <>
          <Grid item md={3} xs={12}>
            <TextField
              id="accountBank"
              name="accountBank"
              fullWidth
              select
              label="Banco"
              error={!!errors.accountBank[0]}
              helperText={errors.accountBank[0]}
              onChange={onChange}
              value={accountBank}>
              {accountBanks.map((bank) => (
                <MenuItem key={bank.value} value={bank.value}>
                  {bank.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              id="accountType"
              name="accountType"
              fullWidth
              select
              label="Tipo de cuenta"
              error={!!errors.accountType[0]}
              helperText={errors.accountType[0]}
              onChange={onChange}
              value={accountType}>
              {accountTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              id="accountHolderNumber"
              name="accountHolderNumber"
              fullWidth
              label="Número de cuenta"
              variant="outlined"
              error={!!errors.accountHolderNumber[0]}
              helperText={errors.accountHolderNumber[0]}
              onChange={onChange}
              value={accountHolderNumber}
            />
          </Grid>
          <Hidden smDown>
            <Grid item xs />
          </Hidden>
        </>
      )}
      {rut && accountHolderRut && rut !== accountHolderRut && (
        <Grid item md={9} xs={12}>
          <Alert severity="warning" sx={{ fontWeight: 'bold' }}>
            Los RUT del solicitante y el titular de la cuenta no coinciden. La solicitud podría ser
            rechazada por este motivo.
          </Alert>
        </Grid>
      )}
    </>
  );
}

export default PaymentMethodForm;
