import { Grid, List, ListItemText, TextField, Typography } from '@mui/material';
import React from 'react';

function RequestStatusView({ objectionReasonsSelected, objectionObservations }) {
  return (
    <>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: 700, fontSize: '16px', color: '#626262' }}>
          Motivos de objeción
        </Typography>
        <List sx={{ listStyleType: 'disc', ml: 4, pt: 0, fontSize: '12px' }}>
          {objectionReasonsSelected.map((reason) => (
            <ListItemText
              key={reason.id}
              sx={{ display: 'list-item', color: '#626262' }}
              primary={reason.name}
            />
          ))}
        </List>
      </Grid>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <TextField
          id="observations"
          name="observations"
          fullWidth
          multiline
          rows={4}
          disabled
          variant="outlined"
          label="Observaciones"
          value={objectionObservations || ''}
          sx={{
            backgroundColor: '#F7F7F7',
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000',
            },
          }}
        />
      </Grid>
    </>
  );
}

export default RequestStatusView;
