/* Redux Saga */
import { all } from 'redux-saga/effects';

/* Project */
import AppSaga from 'content/app.saga';
import { RecoverySaga, LoginSaga, RegisterSaga } from 'content/features/authentication';
import { UserMaintainerSaga } from 'content/features/user-maintainer';
import { BeneficiaryRequestsSaga } from 'content/features/beneficiary-requests';
import { BeneficiaryRequestSaga } from 'content/features/beneficiary-request';
import { BeneficiaryRequestDetailSaga } from 'content/features/beneficiary-request-detail';
import { OperatorRequestsSaga } from 'content/features/operator-requests';
import { OperatorRequestDetailSaga } from 'content/features/operator-request-detail';
import { BeneficiaryAmendmentSaga } from 'content/features/beneficiary-amendment';

export default function* MainSaga() {
  yield all([
    LoginSaga(),
    AppSaga(),
    RecoverySaga(),
    RegisterSaga(),
    UserMaintainerSaga(),
    BeneficiaryRequestsSaga(),
    BeneficiaryRequestSaga(),
    BeneficiaryRequestDetailSaga(),
    OperatorRequestsSaga(),
    OperatorRequestDetailSaga(),
    BeneficiaryAmendmentSaga(),
  ]);
}
