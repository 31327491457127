export const ADMIN = 'admin';
export const OPERATOR = 'operator';
export const BENEFICIARY = 'beneficiary';
export const ALL = [ADMIN, OPERATOR, BENEFICIARY];

const roles = Object.seal({
  ADMIN,
  OPERATOR,
  BENEFICIARY,
  ALL,
});

export const rolesToSelect = [
  { value: 'admin', label: 'Administrador' },
  { value: 'operator', label: 'Operador' },
  { value: 'beneficiary', label: 'Beneficiario' },
];

export default roles;
