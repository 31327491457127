import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/* Extra */
import Grid from '@mui/material/Grid';
import { IconButton, Typography, Link, Container, useMediaQuery } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

/* Project */
const deloitteDesktop = `${window.location.origin}/images/deloitte-desktop.png`;
const deloitteMobile = `${window.location.origin}/images/deloitte-mobile.png`;

function Footer() {
  const location = useLocation();
  const navigate = useNavigate();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  if (location.pathname !== '/') return <div className="footer" style={{ minHeight: '7vh' }} />;

  return (
    <div
      className="footer"
      style={{
        minHeight: '25vh',
        backgroundColor: 'black',
        color: 'white',
        paddingTop: '35px',
      }}>
      <Container>
        <Grid container justifyContent="space-between">
          <Grid item display="flex" flexDirection="column">
            <IconButton onClick={() => navigate('/')} sx={{ paddingLeft: 0, marginBottom: '10px' }}>
              <img
                style={{ height: '40px' }}
                src={isMobile ? deloitteMobile : deloitteDesktop}
                alt=""
              />
            </IconButton>
            <Link
              href="/documents/06%20Pol%C3%ADtica%20de%20Protecci%C3%B3n%20de%20Datos%20Conciliaci%C3%B3n%20Carrier.pdf"
              download="protección-datos-personales.pdf">
              Declaración de Privacidad
            </Link>
          </Grid>
          <Grid item>
            <Typography gutterBottom>Para más información:</Typography>
            <Typography gutterBottom>
              <PhoneIcon sx={{ paddingRight: 1 }} />
              227297000
            </Typography>
            <Typography gutterBottom display="flex" alignItems="center">
              <EmailIcon sx={{ paddingRight: 1 }} />
              conciliacioncarrier@deloitte.com
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Footer;
