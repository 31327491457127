import { createAction } from '@reduxjs/toolkit';
import BeneficiaryAmendmentTypes from 'content/features/beneficiary-amendment/beneficiaryAmendment.types';

export const fetchFormInitialData = createAction(BeneficiaryAmendmentTypes.FETCH_FORM_INITIAL_DATA);

export const fetchFormInitialDataFail = createAction(
  BeneficiaryAmendmentTypes.FETCH_FORM_INITIAL_DATA_FAIL,
);

export const fetchFormInitialDataSuccess = createAction(
  BeneficiaryAmendmentTypes.FETCH_FORM_INITIAL_DATA_SUCCESS,
);

export const createBeneficiaryAmendment = createAction(
  BeneficiaryAmendmentTypes.CREATE_BENEFICIARY_AMENDMENT,
);

export const createBeneficiaryAmendmentFail = createAction(
  BeneficiaryAmendmentTypes.CREATE_BENEFICIARY_AMENDMENT_FAIL,
);

export const createBeneficiaryAmendmentSuccess = createAction(
  BeneficiaryAmendmentTypes.CREATE_BENEFICIARY_AMENDMENT_SUCCESS,
);

export const setControl = createAction(BeneficiaryAmendmentTypes.SET_CONTROL, (payload) => ({
  payload: {
    ...payload,
  },
}));

export const clearScreenData = createAction(BeneficiaryAmendmentTypes.CLEAR_SCREEN_DATA);

export const fetchRequest = createAction(BeneficiaryAmendmentTypes.FETCH_REQUEST);

export const fetchRequestSuccess = createAction(BeneficiaryAmendmentTypes.FETCH_REQUEST_SUCCESS);

export const fetchRequestFail = createAction(BeneficiaryAmendmentTypes.FETCH_REQUEST_FAIL);

export const updateBeneficiaryAmendment = createAction(
  BeneficiaryAmendmentTypes.UPDATE_BENEFICIARY_AMENDMENT,
);

export const updateBeneficiaryAmendmentSuccess = createAction(
  BeneficiaryAmendmentTypes.UPDATE_BENEFICIARY_AMENDMENT_SUCCESS,
);

export const updateBeneficiaryAmendmentFail = createAction(
  BeneficiaryAmendmentTypes.UPDATE_BENEFICIARY_AMENDMENT_FAIL,
);

const BeneficiaryAmendmentActions = {
  fetchFormInitialData,
  fetchFormInitialDataFail,
  fetchFormInitialDataSuccess,
  createBeneficiaryAmendment,
  createBeneficiaryAmendmentFail,
  createBeneficiaryAmendmentSuccess,
  setControl,
  clearScreenData,
  fetchRequest,
  fetchRequestSuccess,
  fetchRequestFail,
  updateBeneficiaryAmendment,
  updateBeneficiaryAmendmentSuccess,
  updateBeneficiaryAmendmentFail,
};

export default BeneficiaryAmendmentActions;
