import React from 'react';
import CustomTypographyView from 'content/shared/Views/CustomTypographyView';

function PaymentMethodView({
  accountPaymentMethod,
  accountHolderName,
  accountHolderTaxNumber,
  accountBank,
  accountType,
  accountNumber,
}) {
  return (
    <>
      <CustomTypographyView label="Método de pago" text={accountPaymentMethod} />
      <CustomTypographyView label="Titular de la cuenta" text={accountHolderName} />
      <CustomTypographyView label="RUT/RUN del titular" text={accountHolderTaxNumber} />
      {accountPaymentMethod === 'TRANSFERENCIA' && (
        <>
          <CustomTypographyView label="Banco" text={accountBank} />
          <CustomTypographyView label="Tipo de cuenta" text={accountType} />
          <CustomTypographyView label="Número de cuenta" text={accountNumber} />
        </>
      )}
    </>
  );
}

export default PaymentMethodView;
