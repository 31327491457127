import React, { useState } from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import CustomDropzoneView from 'content/shared/Views/CustomDropzoneView';
import ModalImageView from 'content/shared/Views/ModalImageView';

function MobileVersion({
  frontIdCard,
  backIdCard,
  carInscription,
  modalImage,
  handleCloseModal,
  handleOpenModal,
  modalOpen,
}) {
  return (
    <Grid container item xs={12} spacing={8} sx={{ mb: 8 }}>
      <Grid item xs={12}>
        <Typography variant="body2" sx={{ color: '#626262', mb: 1 }}>
          Cara delantera cédula de identidad del solicitante
        </Typography>
        <CustomDropzoneView
          file={frontIdCard}
          fileName="Cara delantera cédula de identidad"
          handleOpenModal={handleOpenModal}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" sx={{ color: '#626262', mb: 1 }}>
          Cara trasera cédula de identidad del solicitante
        </Typography>
        <CustomDropzoneView
          file={backIdCard}
          fileName="Cara trasera cédula de identidad"
          handleOpenModal={handleOpenModal}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" sx={{ color: '#626262', mb: 1 }}>
          Certificado de inscripción y anotaciones vigentes del vehículo
        </Typography>
        <CustomDropzoneView
          file={carInscription}
          fileName="Certificado de inscripción y anotaciones vigentes"
          handleOpenModal={handleOpenModal}
        />
      </Grid>
      <ModalImageView
        handleCloseModal={handleCloseModal}
        modalImage={modalImage}
        modalOpen={modalOpen}
      />
    </Grid>
  );
}

function DesktopVersion({
  frontIdCard,
  backIdCard,
  carInscription,
  modalImage,
  handleCloseModal,
  handleOpenModal,
  modalOpen,
}) {
  return (
    <Grid container item xs={12} spacing={4} sx={{ mb: 8 }}>
      <Grid item container md={6} spacing={6}>
        <Grid item xs={12}>
          <Typography variant="body2" sx={{ color: '#626262', mb: 1 }}>
            Cara delantera cédula de identidad del solicitante
          </Typography>
          <CustomDropzoneView
            file={frontIdCard}
            fileName="Cara delantera cédula de identidad"
            handleOpenModal={handleOpenModal}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" sx={{ color: '#626262', mb: 1 }}>
            Cara trasera cédula de identidad del solicitante
          </Typography>
          <CustomDropzoneView
            file={backIdCard}
            fileName="Cara trasera cédula de identidad"
            handleOpenModal={handleOpenModal}
          />
        </Grid>
      </Grid>
      <Grid item container md={6}>
        <Grid item xs={12}>
          <Typography variant="body2" sx={{ color: '#626262', mb: 1 }}>
            Certificado de inscripción y anotaciones vigentes del vehículo
          </Typography>
          <CustomDropzoneView
            file={carInscription}
            fileName="Certificado de inscripción y anotaciones vigentes"
            handleOpenModal={handleOpenModal}
          />
        </Grid>
      </Grid>
      <ModalImageView
        handleCloseModal={handleCloseModal}
        modalImage={modalImage}
        modalOpen={modalOpen}
      />
    </Grid>
  );
}

function PersonalDocumentsView({ frontIdCard, backIdCard, carInscription }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  const handleOpenModal = (imageUrl) => {
    setModalImage(imageUrl);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return isMobile ? (
    <MobileVersion
      frontIdCard={frontIdCard}
      backIdCard={backIdCard}
      carInscription={carInscription}
      modalOpen={modalOpen}
      handleOpenModal={handleOpenModal}
      handleCloseModal={handleCloseModal}
      modalImage={modalImage}
    />
  ) : (
    <DesktopVersion
      frontIdCard={frontIdCard}
      backIdCard={backIdCard}
      carInscription={carInscription}
      modalOpen={modalOpen}
      handleOpenModal={handleOpenModal}
      handleCloseModal={handleCloseModal}
      modalImage={modalImage}
    />
  );
}

export default PersonalDocumentsView;
