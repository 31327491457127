import { Grid, Typography } from '@mui/material';
import React from 'react';

function CustomTypographyView({ label = '', text = '' }) {
  return (
    <Grid item md={3} xs={12}>
      <Typography
        id="vin"
        sx={{
          color: '#00000099',
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '12px',
          letterSpacing: '0.15000000596046448px',
          textAlign: 'left',
          pb: 1,
        }}>
        {label || 'Sin etiqueta'}
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '24px',
          letterSpacing: '0.15000000596046448px',
          textAlign: 'left',
          wordBreak: 'break-word',
        }}>
        {text || 'Sin información'}
      </Typography>
    </Grid>
  );
}

export default CustomTypographyView;
