import { Grid, MenuItem, TextField } from '@mui/material';
import React, { useMemo } from 'react';

function VehicleForm({ onChange, errors, controls, initialData, amendment }) {
  const { vehicleBrands, vehicleModels, vehicleYears } = initialData;
  const { vin, licensePlate, brand, model, year } = controls;

  const modelsFiltered = useMemo(
    () => vehicleModels.filter((vModel) => vModel.brandId === brand),
    [brand, vehicleModels],
  );

  const yearsFiltered = useMemo(
    () => vehicleYears.filter((vYear) => vYear.modelId === model),
    [model, vehicleYears],
  );

  return (
    <>
      <Grid item md={3} xs={12}>
        <TextField
          id="vin"
          name="vin"
          fullWidth
          label="VIN o número de vehículo"
          variant="outlined"
          error={!!errors.vin[0]}
          helperText={errors.vin[0]}
          onChange={onChange}
          value={vin}
          inputProps={{ maxLength: 17 }}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          id="licensePlate"
          name="licensePlate"
          fullWidth
          label="Patente"
          variant="outlined"
          disabled={amendment}
          error={!!errors.licensePlate[0]}
          helperText={errors.licensePlate[0]}
          onChange={onChange}
          value={licensePlate}
          inputProps={{ maxLength: 6 }}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          id="brand"
          name="brand"
          fullWidth
          select
          label="Marca"
          error={!!errors.brand[0]}
          helperText={errors.brand[0]}
          onChange={onChange}
          value={brand}>
          {vehicleBrands.map((vBrand) => (
            <MenuItem key={vBrand.value} value={vBrand.value}>
              {vBrand.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          id="model"
          name="model"
          fullWidth
          select
          label="Modelo"
          disabled={!brand}
          error={!!errors.model[0]}
          helperText={errors.model[0]}
          onChange={onChange}
          value={model}>
          {modelsFiltered.map((fModel) => (
            <MenuItem key={fModel.value} value={fModel.value}>
              {fModel.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          id="year"
          name="year"
          fullWidth
          select
          label="Año"
          disabled={!model}
          error={!!errors.year[0]}
          helperText={errors.year[0]}
          onChange={onChange}
          value={year}>
          {yearsFiltered.map((fYear) => (
            <MenuItem key={fYear.value} value={fYear.value}>
              {fYear.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </>
  );
}

export default VehicleForm;
