const pageSelector = (state) => ({
  controls: state.login.controls,
  loading: state.login.loading,
  loginError: state.login.error,
  user: state.app.user,
  error: state.app.error,
  errorMsg: state.app.errorMsg,
  success: state.app.success,
  successMsg: state.app.successMsg,
  message: state.login.message,
});

const LoginSelector = {
  page: pageSelector,
};

export default LoginSelector;
