import React from 'react';
import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import { Search } from '@mui/icons-material';

function UserFilter({ onChange, controls, handleSearch, handleClear }) {
  const { userId, name } = controls;

  return (
    <Paper
      elevation={3}
      sx={{ padding: 3, borderRadius: '8px', boxShadow: '0px 2px 29px 0px #00000012' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              fontSize: '24px',
              fontWeight: 600,
              lineHeight: '49.03px',
            }}>
            Buscador
          </Typography>
        </Grid>
        <Grid item md={3} xs={6}>
          <TextField
            id="userId"
            name="userId"
            fullWidth
            label="ID de usuario"
            variant="outlined"
            onChange={onChange}
            value={userId}
            inputProps={{ maxLength: 6 }}
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <TextField
            id="name"
            name="name"
            label="Nombre"
            fullWidth
            variant="outlined"
            onChange={onChange}
            value={name}
          />
        </Grid>
        <Grid item md={6} xs={12} container justifyContent="flex-end">
          <Button
            variant="text"
            sx={{ marginRight: 2, textTransform: 'none', fontWeight: 'bold', minWidth: '193px' }}
            size="large"
            onClick={handleClear}>
            Limpiar búsqueda
          </Button>
          <Button
            variant="outlined"
            size="large"
            startIcon={<Search />}
            sx={{ textTransform: 'none', fontWeight: 'bold', minWidth: '135px' }}
            onClick={handleSearch}>
            Buscar
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default UserFilter;
