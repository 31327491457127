import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ExpandMore, FindInPage } from '@mui/icons-material';
import ColorCodedChip from 'content/shared/ColorCodedChip/ColorCodedChip';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import EditDocument from './EditDocument';

function MobileTable({ rows }) {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width:400px)');

  return (
    <>
      <Card
        sx={{
          backgroundColor: 'black',
          borderRadius: '4px 4px 0 0',
          padding: 1,
          paddingLeft: 2,
        }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '85%' }}>
          <Typography variant="body1" sx={{ color: 'white' }}>
            Patente
          </Typography>
          <Typography variant="body1" sx={{ color: 'white' }}>
            Estado
          </Typography>
        </Stack>
      </Card>
      {rows.length === 0 && (
        <Typography variant="body2" sx={{ textAlign: 'center', mt: 2 }}>
          No hay solicitudes
        </Typography>
      )}
      {
        // eslint-disable-next-line complexity
        rows.map((row) => (
          <Accordion key={row.id} disableGutters elevation={0} square>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="accordion-content"
              id="accordion-header">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: '95%' }}>
                <Typography variant="body2">{row.vehicleLicensePlate}</Typography>
                <ColorCodedChip state={row.requestStatus} />
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  Fecha de solicitud
                </Typography>
                <Typography variant="body2" sx={{ color: 'grey', mb: 1 }}>
                  {new Date(row.createdAt).toLocaleDateString('es-CL')}
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  Vehículo
                </Typography>
                <Typography variant="body2" sx={{ color: 'grey', mb: 1 }}>
                  {row.vehicleData}
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  Tipo de solicitud
                </Typography>
                <Typography variant="body2" sx={{ color: 'grey', mb: 1 }}>
                  {row.requestType}
                </Typography>
                <Stack
                  direction={isSmallScreen ? 'column' : 'row'}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={isSmallScreen ? 2 : 6}>
                  <IconButton
                    aria-label="see request"
                    color="primary"
                    sx={{ p: 0, m: 0 }}
                    onClick={() => {
                      if (row.requestStatus === 'Objetada') {
                        navigate(`/amendment/${row.id}`);
                      } else {
                        navigate(`/requests/${row.id}`);
                      }
                    }}>
                    <Typography
                      variant="body2"
                      sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                      <FindInPage />
                      Ver solicitud
                    </Typography>
                  </IconButton>
                  <IconButton
                    aria-label="amend request"
                    color="primary"
                    disabled={
                      row.requestStatus !== 'Objetada' || row.hasAmendment || !!row.amendmentAt
                    }
                    onClick={() => {
                      navigate(`/amendment/${row.id}`);
                    }}
                    sx={{ p: 0, m: 0 }}>
                    <Typography
                      variant="body2"
                      sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                      <EditDocument />
                      Enmendar solicitud
                    </Typography>
                  </IconButton>
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
        ))
      }
    </>
  );
}

export default MobileTable;
