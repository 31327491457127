import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FileUpload } from '@mui/icons-material';
import { Card, CardMedia, Grid, Typography } from '@mui/material';
import { borderColorFunction } from 'utils/helper';

const preLoadedFileText = (dropId, fileExtension) => {
  const preLoadedFileName = {
    frontIdentityDocument: 'cédula de identidad frontal',
    backIdentityDocument: 'cédula de identidad trasera',
    carInscriptionDocument: 'certificado de inscripción y anotaciones vigentes del vehículo',
  };
  return `${preLoadedFileName[dropId]}.${fileExtension}`;
};

// eslint-disable-next-line complexity
function CustomDropzone({ dropId, onChange, errors, preLoadedFile = null }) {
  const [preview, setPreview] = useState([]);
  const error = errors[dropId];
  const borderColor = borderColorFunction(error, '#002855BF');

  const onDrop = useCallback(
    (acceptedFiles) => {
      setPreview(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
      const [file] = acceptedFiles;
      if (file) {
        onChange({ id: dropId, name: dropId, value: file, type: 'dropzone' });
      }
    },
    [onChange, dropId],
  );

  const onDropRejected = useCallback(() => {
    setPreview([]);
    onChange({ id: dropId, name: dropId, value: null, type: 'dropzone' });
  }, [onChange, dropId]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    multiple: false,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
      'application/pdf': [],
    },
  });

  const fileExtension = preLoadedFile ? preLoadedFile.ContentType.split('/')[1] : null;

  useEffect(() => {
    if (preLoadedFile) {
      const blob = new Blob([Uint8Array.from(preLoadedFile.Body.data)]);

      const file = new File([blob], preLoadedFileText(dropId, fileExtension), {
        type: preLoadedFile.ContentType,
      });
      if (file) {
        Object.assign(file, {
          preview: URL.createObjectURL(blob),
        });

        setPreview([file]);
        onChange({ id: dropId, name: dropId, value: file, type: 'dropzone' });
      }
    }

    return () => {
      if (preview) {
        URL.revokeObjectURL(preview.preview);
      }
    };
  }, [preLoadedFile]);

  return (
    <Grid
      component="div"
      container
      justifyContent="center"
      alignItems="center"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...getRootProps()}
      style={{
        border: `2px dashed ${borderColor}`,
        borderRadius: '5px',
        textAlign: 'center',
        cursor: 'pointer',
        padding: '20px',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(134, 188, 37, 0.1)',
      }}>
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input id={dropId} {...getInputProps()} />
        {preview.length <= 0 && (
          <Typography
            variant="body2"
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              color: '#626262',
            }}>
            <FileUpload sx={{ color: '#626262' }} />
            {isDragActive ? 'Suelta aquí el archivo' : 'Arrastra o adjunta el archivo'}
          </Typography>
        )}
        {preview.map((file) => {
          if (file.type === 'application/pdf') {
            return (
              <Grid
                key={file.name + file.size}
                item
                container
                xs={12}
                justifyContent="center"
                alignItems="center"
                spacing={2}>
                <Grid item xs={12} md={8}>
                  <Typography
                    variant="body2"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      color: '#626262',
                      wordBreak: 'break-word',
                    }}>
                    {file.name}
                  </Typography>
                </Grid>
              </Grid>
            );
          }
          return (
            <Grid
              key={file.name + file.size}
              item
              container
              xs={12}
              justifyContent="center"
              alignItems="center"
              spacing={2}>
              <Grid item xs={4} md={4}>
                <Card
                  sx={{
                    align: 'center',
                    m: 0,
                    p: 0,
                    maxWidth: 100,
                  }}>
                  <CardMedia
                    component="img"
                    alt={`preview ${file.name}`}
                    image={file.preview}
                    onLoad={() => {
                      URL.revokeObjectURL(file.preview);
                    }}
                  />
                </Card>
              </Grid>
              <Grid item xs={8} md={6}>
                <Typography
                  variant="body2"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    color: '#626262',
                    wordBreak: 'break-word',
                  }}>
                  {file.name}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

export default CustomDropzone;
