import { createAction } from '@reduxjs/toolkit';

import BeneficiaryRequestDetailTypes from './beneficiaryRequestDetail.types';

export const fetchBeneficiaryRequestDetail = createAction(
  BeneficiaryRequestDetailTypes.FETCH_REQUEST,
  (payload) => ({
    payload: {
      ...payload,
    },
  }),
);

export const fetchBeneficiaryRequestDetailFail = createAction(
  BeneficiaryRequestDetailTypes.FETCH_REQUEST_FAIL,
);

export const fetchBeneficiaryRequestDetailSuccess = createAction(
  BeneficiaryRequestDetailTypes.FETCH_REQUEST_SUCCESS,
);

const BeneficiaryRequestDetailActions = {
  fetchBeneficiaryRequestDetail,
  fetchBeneficiaryRequestDetailFail,
  fetchBeneficiaryRequestDetailSuccess,
};

export default BeneficiaryRequestDetailActions;
