import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

/* Material UI */
import {
  Box,
  CardMedia,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

/* React Router */
import { useLocation, useNavigate } from 'react-router-dom';

/* Project */
import Paths from 'utils/paths';

function TemporaryDrawer(props) {
  const appState = useSelector((state) => state.app);
  const userRole = appState.user?.type || 'guest';

  const { side, openByDefault, setOpenDrawer } = props;

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const tabValidation = (event) => event.key === 'Tab' || event.key === 'Shift';
  const toggleValidations = (event) => event.type === 'keydown' && tabValidation(event);

  const deloitteDesktopBlack = `${window.location.origin}/images/deloitte-desktop-negro.png`;

  const toggleDrawer = (anchor, open) => (event) => {
    if (toggleValidations(event)) return;

    setState({ ...state, [anchor]: open });
  };

  const handleRouteClick = (path) => {
    navigate(path);
    setOpenDrawer(false);
  };

  const { publics, shared, protecteds } = Paths;

  const views = [...shared, ...publics, ...protecteds]
    .filter((path) => path.name)
    .filter((path) => path.allowedRoles.includes(userRole))
    .map(
      // eslint-disable-next-line complexity
      ({ Icon, path, name }) => (
        <ListItem key={path}>
          <ListItemButton
            onClick={() => handleRouteClick(path)}
            sx={{
              borderRadius: '8px',
              padding: '12px 16px 12px 16px',
              backgroundColor: location.pathname === path && '#f5ffe3',
            }}>
            <ListItemIcon
              sx={{
                color: location.pathname === path && '#86BC25',
                minWidth: '24px',
                marginRight: '10px',
              }}>
              {Icon ? <Icon /> : null}
            </ListItemIcon>
            <ListItemText primary={name} />
          </ListItemButton>
        </ListItem>
      ),
    );

  const list = (anchor) => (
    <Box
      sx={{ width: ['top', 'bottom'].includes(anchor) ? 'auto' : 300, color: '#333333' }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <Paper
        sx={{ height: '110px', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}
        elevation={0}
        square>
        <IconButton aria-label="close" onClick={() => setOpenDrawer(false)}>
          <CloseIcon />
        </IconButton>
        <CardMedia
          image={deloitteDesktopBlack}
          sx={{
            width: '100%',
            height: '100%',
            backgroundSize: '188px',
          }}
        />
      </Paper>
      <List>{views}</List>
    </Box>
  );

  return (
    <Drawer
      anchor={side}
      open={openByDefault}
      onClose={() => setOpenDrawer(false)}
      test-dataid="app-drawer">
      {list(side)}
    </Drawer>
  );
}

TemporaryDrawer.propTypes = {
  setOpenDrawer: PropTypes.func.isRequired,
  side: PropTypes.string,
  openByDefault: PropTypes.bool,
};

TemporaryDrawer.defaultProps = {
  side: 'left',
  openByDefault: false,
};

export default TemporaryDrawer;
