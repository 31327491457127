import { createAction } from '@reduxjs/toolkit';

import AppTypes from './app.types';

export const getStateFromApi = createAction(AppTypes.GET_STATE_FROM_API);

export const getStateFromApiSuccess = createAction(AppTypes.GET_STATE_FROM_API_SUCCESS);

export const getStateReplaceFromApiSuccess = createAction(
  AppTypes.GET_STATE_REPLACE_FROM_API_SUCCESS,
);

export const toggleErrorMessage = createAction(AppTypes.TOGGLE_ERROR_MESSAGE);

export const setErrorMessage = createAction(AppTypes.SET_ERROR_MESSAGE);

export const toggleSuccessMessage = createAction(AppTypes.TOGGLE_SUCCESS_MESSAGE);

export const setSuccessMessage = createAction(AppTypes.SET_SUCCESS_MESSAGE);

export const toggleConfirmationDialog = createAction(AppTypes.TOGGLE_CONFIRMATION_DIALOG);

export const mergeObjectsReducer = createAction(AppTypes.MERGE_OBJECTS);

export const deleteObjectsReducer = createAction(AppTypes.DELETE_OBJECTS);

export const mergeDeleteObjectsReducer = createAction(AppTypes.MERGE_DELETE_OBJECTS);

export const setPwaPromptEvent = createAction(AppTypes.SET_PWA_PROMPT_EVENT);

export const setLoader = createAction(AppTypes.SET_LOADER, (id, value) => ({
  payload: { id, value },
}));

const AppActions = {
  getStateFromApi,
  getStateFromApiSuccess,
  getStateReplaceFromApiSuccess,
  toggleErrorMessage,
  setErrorMessage,
  toggleSuccessMessage,
  setSuccessMessage,
  toggleConfirmationDialog,
  setPwaPromptEvent,
};

export default AppActions;
