import React, { useEffect } from 'react';

/* Material UI */
import { createTheme, ThemeProvider } from '@mui/material';

/* React Redux  */
import { useDispatch, useSelector } from 'react-redux';

/* React Router */
import { useNavigate } from 'react-router-dom';

/* Toastify */
import { ToastContainer } from 'react-toastify';

import useConnectionStatus from 'hooks/useConnectionStatus';
import useNotification from 'hooks/useNotifications';
/* Project */
import environments from 'config/environments';
import useSessionTimeout from 'hooks/useSessionTimeout';
import defaultTheme from 'utils/theme';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import { getStateFromApi, setPwaPromptEvent } from './app.actions';
import './App.scss';
import Router from './handlers/Router';
import { samlLogin } from './features/authentication/login/login.actions';

function App({ route }) {
  useSessionTimeout(30 * 60 * 1000); // 30 minutes
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOnline } = useConnectionStatus();
  const { requestNotificationPermission } = useNotification();

  useEffect(() => {
    // Redirect route for test environment
    if (route) navigate(route);
  }, [route]);

  const user = useSelector((state) => state.app.user);
  const loading = useSelector((state) => state.app.loading);

  useEffect(() => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: environments.GOOGLE_CLIENT_ID,
      });
    }

    dispatch(getStateFromApi());
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', () => {
      requestNotificationPermission();
    });

    return () => {
      document.body.removeEventListener('click', () => {
        requestNotificationPermission();
      });
    };
  }, []);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      dispatch(
        setPwaPromptEvent({
          promptEvent: event,
        }),
      );
    });
  }, []);

  useEffect(() => {
    if (isOnline) {
      // defaultSuccessToast({ message: 'Estás en línea' });
    } else {
      defaultErrorToast({ message: 'Estás desconectado' });
    }
  }, [isOnline]);
  const theme = createTheme(defaultTheme);

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  useEffect(() => {
    if (token) {
      dispatch(samlLogin({ token }));
    }
  }, [token]);

  return (
    <ThemeProvider theme={theme}>
      <Router user={user} loading={loading} />
      <ToastContainer newestOnTop />
    </ThemeProvider>
  );
}

export default App;
