import React from 'react';
import { Card, CardMedia, Modal } from '@mui/material';

function ModalImageView({ modalOpen, handleCloseModal, modalImage }) {
  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Card
        sx={{
          width: 400,
          maxWidth: '90%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
        <CardMedia component="img" height="auto" image={modalImage} alt="Imagen Modal" />
      </Card>
    </Modal>
  );
}

export default ModalImageView;
