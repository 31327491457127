/* eslint-disable complexity */
import React, { useEffect } from 'react';

/* Extra */
import { Grid, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { format, parseISO } from 'date-fns';

function BeneficiarySignature({ errors, onChange, controls, setControl, validate }) {
  const dispatch = useDispatch();

  const { signature, signatureDate, names, paternalSurname, maternalSurname } = controls;

  const fullName = `${controls.names} ${controls.paternalSurname || ''} ${
    controls.maternalSurname || ''
  }`;

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (names || paternalSurname || maternalSurname) {
      dispatch(setControl({ name: 'signature', value: fullName }));
      dispatch(setControl({ name: 'accountHolderName', value: fullName }));
      validate('signature', fullName);
    }
  }, [names, paternalSurname, maternalSurname]);

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        mt: { md: 4, xs: 6 },
      }}>
      <Grid item xs={12} sm={6}>
        <TextField
          id="signature"
          name="signature"
          label="Firma"
          fullWidth
          variant="outlined"
          error={!!errors.signature[0]}
          helperText={errors.signature[0]}
          onChange={onChange}
          value={signature}
        />
      </Grid>
      <Grid item sm={6} xs={0} />
      <Grid item xs={12} sm={3} mt={2}>
        <TextField
          disabled
          id="signatureDate"
          name="signatureDate"
          label="Fecha de firma"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={format(parseISO(signatureDate), 'dd-MM-yyyy')}
          error={!!errors.signatureDate[0]}
          helperText={errors.signatureDate[0] ? 'Fecha inválida' : ''}
        />
      </Grid>
    </Grid>
  );
}

export default BeneficiarySignature;
