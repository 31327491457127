import { Search } from '@mui/icons-material';
import { Button, Grid, MenuItem, Paper, TextField, Typography } from '@mui/material';
import React from 'react';

// eslint-disable-next-line complexity
function Filters({ onChange, initialData, controls, handleSearch, handleClear, errors }) {
  const { requestId, startDate, endDate, licensePlate, states, operator, rut } = controls;
  const { operators, statesValues } = initialData;

  return (
    <Paper
      elevation={3}
      sx={{ padding: 3, borderRadius: '8px', boxShadow: '0px 2px 29px 0px #00000012' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              fontSize: '24px',
              fontWeight: 600,
              lineHeight: '49.03px',
            }}>
            Buscador
          </Typography>
        </Grid>
        <Grid item md={2.4} xs={6}>
          <TextField
            id="requestId"
            name="requestId"
            type="number"
            fullWidth
            label="ID de solicitud"
            variant="outlined"
            onChange={onChange}
            value={requestId}
            inputProps={{ maxLength: 6 }}
            error={errors.requestId}
            helperText={errors.requestId ? 'ID de solicitud inválido' : ''}
            onKeyDown={(e) => e.key === '.' && e.preventDefault()}
          />
        </Grid>
        <Grid item md={2.4} xs={6}>
          <TextField
            id="startDate"
            name="startDate"
            label="Fecha desde"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={onChange}
            value={startDate}
            error={errors.startDate}
            helperText={errors.startDate ? 'Fecha inválida' : ''}
          />
        </Grid>
        <Grid item md={2.4} xs={6}>
          <TextField
            id="endDate"
            name="endDate"
            label="Fecha hasta"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={onChange}
            value={endDate}
            error={errors.endDate}
            helperText={errors.endDate ? 'Fecha inválida o anterior a la fecha de inicio' : ''}
          />
        </Grid>
        <Grid item md={2.4} xs={6}>
          <TextField
            id="rut"
            name="rut"
            label="RUT beneficiario"
            fullWidth
            variant="outlined"
            onChange={onChange}
            value={rut}
            error={errors.rut}
            helperText={errors.rut ? 'RUT inválido' : ''}
          />
        </Grid>
        <Grid item md={2.4} xs={6}>
          <TextField
            id="licensePlate"
            name="licensePlate"
            fullWidth
            label="Patente"
            variant="outlined"
            inputProps={{ maxLength: 6 }}
            onChange={onChange}
            value={licensePlate}
            error={errors.licensePlate}
            helperText={errors.licensePlate ? 'Patente inválida' : ''}
          />
        </Grid>
        <Grid item md={2.4} xs={6}>
          <TextField
            id="states"
            name="states"
            fullWidth
            select
            label="Estado"
            onChange={onChange}
            value={states}>
            {statesValues.map((st) => (
              <MenuItem key={st.value} value={st.value}>
                {st.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={4.8} xs={6}>
          <TextField
            id="operator"
            name="operator"
            fullWidth
            select
            label="Operador"
            onChange={onChange}
            value={operator}>
            <MenuItem key="null" value="null">
              Sin Asignar
            </MenuItem>
            {operators.map((op) => (
              <MenuItem key={op.value} value={op.value}>
                {op.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={4.8} xs={12} container justifyContent="flex-end">
          <Button
            variant="text"
            sx={{ marginRight: 2, textTransform: 'none', fontWeight: 'bold', minWidth: '193px' }}
            size="large"
            onClick={handleClear}>
            Limpiar búsqueda
          </Button>
          <Button
            variant="outlined"
            size="large"
            startIcon={<Search />}
            sx={{ textTransform: 'none', fontWeight: 'bold', minWidth: '135px' }}
            onClick={handleSearch}>
            Buscar
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Filters;
