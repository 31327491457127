import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';

// eslint-disable-next-line complexity
function RequestStatusForm({ errors, onChange, onChangeCheckbox, isAmendment, objectedRequestId }) {
  const { controls, initialData, request } = useSelector((state) => state.operatorRequestDetail);
  const { requestStatusValues, objectionReasons } = initialData;
  const {
    requestStatusControl,
    requestStatusControlError,
    checkOptions,
    checkError,
    observations,
  } = controls;
  const { requestStatus, operatorUserId } = request;

  return (
    <>
      <Grid item md={3} xs={12} sx={{ mt: 2.5 }}>
        <TextField
          id="requestStatusControl"
          name="requestStatusControl"
          fullWidth
          select
          label="Estado de la solicitud"
          error={!!errors.requestStatusControl[0] || requestStatusControlError}
          helperText={
            requestStatusControlError || isAmendment
              ? 'Debe seleccionar un estado para la solicitud diferente del anterior'
              : errors.requestStatusControl[0]
          }
          onChange={onChange}
          disabled={operatorUserId === null}
          value={requestStatusControl}>
          {requestStatusValues.map((rStatus) => (
            <MenuItem
              key={rStatus.value}
              value={rStatus.value}
              disabled={rStatus.value === requestStatus}>
              {rStatus.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {requestStatusControl === objectedRequestId && !isAmendment && (
        <>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Typography fontWeight={600} sx={{ fontSize: '20px' }}>
              Selecciona el o los motivos de rechazo de la solicitud:
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            <FormControl required error={checkError} component="fieldset" variant="standard">
              <FormGroup>
                <Grid container>
                  {objectionReasons.map((reason) => (
                    <Grid item md={6} xs={12} key={reason.value}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkOptions[reason.value]}
                            onChange={onChangeCheckbox}
                            sx={{ fontSize: '16px' }}
                            name={`reason${reason.value}`}
                            id={`reason${reason.value}`}
                          />
                        }
                        label={reason.label}
                        sx={{
                          fontSize: '16px',
                          color: checkError ? '#DA291C' : '#000000',
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
              {checkError && <FormHelperText>Debe seleccionar al menos un motivo</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ mt: 4, mb: 3 }}>
            <TextField
              id="observations"
              name="observations"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              onChange={onChange}
              label="Observaciones"
              error={!!errors.observations[0]}
              helperText={errors.observations[0]}
              value={observations}
            />
          </Grid>
        </>
      )}
    </>
  );
}

export default RequestStatusForm;
