import { spawn, takeLatest, put } from 'redux-saga/effects';

import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import apiRequest from 'utils/api';
import {
  acceptRequest,
  acceptRequestFail,
  acceptRequestSuccess,
  fetchOperatorRequestDetail,
  fetchOperatorRequestDetailFail,
  fetchOperatorRequestDetailSuccess,
  objectRequest,
  payRequest,
  payRequestFail,
  payRequestSuccess,
  rejectRequest,
  rejectRequestFail,
  rejectRequestSuccess,
} from 'content/features/operator-request-detail/operatorRequestDetail.actions';
import { setLoader } from 'content/app.actions';

function* request() {
  yield takeLatest(fetchOperatorRequestDetail, function* fetchOperatorRequestsDetailSaga(action) {
    const { id } = action.payload;
    const response = yield apiRequest(`api/v1/conciliation-request/operator/request/${id}`, {
      method: 'get',
    });
    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(fetchOperatorRequestDetailFail());
    } else {
      defaultSuccessToast({ message: 'Detalle de solicitud cargada exitosamente.' });
      yield put(fetchOperatorRequestDetailSuccess(response));
    }
  });
}

function* accept() {
  yield takeLatest(acceptRequest, function* acceptRequestSaga(action) {
    yield put(setLoader('operatorUpdateRequestButtonId', true));
    const requestId = action.payload;
    const response = yield apiRequest(`api/v1/conciliation-request/operator/accept/${requestId}`, {
      method: 'post',
    });
    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(acceptRequestFail());
    } else {
      defaultSuccessToast({ message: 'Solicitud aceptada exitosamente.' });
      yield put(acceptRequestSuccess());
    }
    yield put(setLoader('operatorUpdateRequestButtonId', false));
  });
}

function* object() {
  yield takeLatest(objectRequest, function* objectRequestSaga(action) {
    yield put(setLoader('operatorUpdateRequestButtonId', true));

    const { objectionReasonsFormatted: objectionReasons, requestId, observations } = action.payload;
    const response = yield apiRequest(`api/v1/conciliation-request/operator/object/${requestId}`, {
      method: 'post',
      body: JSON.stringify({ objectionReasons, observations }),
    });

    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(acceptRequestFail());
    } else {
      defaultSuccessToast({ message: 'Solicitud objetada exitosamente.' });
      yield put(acceptRequestSuccess());
    }
    yield put(setLoader('operatorUpdateRequestButtonId', false));
  });
}

function* reject() {
  yield takeLatest(rejectRequest, function* rejectRequestSaga(action) {
    yield put(setLoader('operatorUpdateRequestButtonId', true));

    const requestId = action.payload;
    const response = yield apiRequest(`api/v1/conciliation-request/operator/reject/${requestId}`, {
      method: 'post',
    });

    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(rejectRequestFail());
    } else {
      defaultSuccessToast({ message: 'Solicitud rechazada exitosamente.' });
      yield put(rejectRequestSuccess());
    }
    yield put(setLoader('operatorUpdateRequestButtonId', false));
  });
}

function* pay() {
  yield takeLatest(payRequest, function* payRequestSaga(action) {
    yield put(setLoader('operatorUpdateRequestButtonId', true));

    const requestId = action.payload;
    const response = yield apiRequest(`api/v1/conciliation-request/operator/pay/${requestId}`, {
      method: 'post',
    });

    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(payRequestFail());
    } else {
      defaultSuccessToast({ message: 'Solicitud pagada exitosamente.' });
      yield put(payRequestSuccess());
    }
    yield put(setLoader('operatorUpdateRequestButtonId', false));
  });
}

export default function* OperatorRequestDetailSaga() {
  yield spawn(request);
  yield spawn(accept);
  yield spawn(object);
  yield spawn(reject);
  yield spawn(pay);
}
