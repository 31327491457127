import React from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

/* React Router */
import { Link } from 'react-router-dom';

/* Project */
import { loginStyles } from 'content/features/authentication';

/* Public */
const deloitteDesktop = `${window.location.origin}/images/deloitte-desktop-negro.png`;

function LoginForm(props) {
  const { goToHome, controls, errors, onChangeText, handleLogin, loading, error, message } = props;
  const handleSamlLogin = () => {
    // Redirige al backend para iniciar sesión con Active Directory (SAML)
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/saml`;
  };

  return (
    <form onSubmit={(evt) => evt.preventDefault()}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100vh', backgroundColor: '#E8EAE9' }}>
        <Grid item md={4} lg={4} xs={12}>
          <Card sx={{ boxShadow: 0, borderRadius: 6, maxWidth: '500px', maxHeight: '620px' }}>
            <CardContent>
              <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={10} sx={{ mt: 4 }}>
                  <CardMedia
                    onClick={goToHome}
                    component="img"
                    style={{
                      cursor: 'pointer',
                      objectFit: 'contain',
                      maxWidth: '250px',
                      margin: 'auto',
                    }}
                    image={deloitteDesktop}
                    title="Deloitte"
                  />
                </Grid>
                <Grid item xs={10} textAlign="center" sx={{ mt: 1 }}>
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '24px',
                      fontWeight: 700,
                      lineHeight: '32.68px',
                    }}>
                    Inicia sesión
                  </Typography>
                </Grid>
                <Grid item xs={10} textAlign="center">
                  <TextField
                    sx={loginStyles.textfield}
                    autoFocus
                    inputProps={{ 'data-testid': 'text-field-email' }}
                    label="Correo electrónico"
                    id="email"
                    value={controls.email}
                    onChange={onChangeText}
                    fullWidth
                    error={!!errors.email.message}
                    helperText={errors.email.message}
                  />
                </Grid>
                <Grid item xs={10} textAlign="center">
                  <TextField
                    sx={loginStyles.textfield}
                    inputProps={{ 'data-testid': 'text-field-password' }}
                    label="Contraseña"
                    onChange={onChangeText}
                    value={controls.password}
                    id="password"
                    fullWidth
                    type="password"
                    error={!!errors.password.message}
                    helperText={errors.password.message}
                  />
                </Grid>
                <Grid item xs={10} sx={{ textAlign: 'right' }}>
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#26890D',
                    }}>
                    <Link to="/recovery" style={{ textDecoration: 'none', color: 'inherit' }}>
                      Olvidé mi contraseña
                    </Link>
                  </Typography>
                </Grid>
                {error && (
                  <Grid item xs={10} textAlign="center">
                    <Alert severity="error" sx={{ fontWeight: 'bold' }}>
                      {message}
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={10} textAlign="center">
                  <LoadingButton
                    data-testid="button-login"
                    onClick={handleLogin}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: '5px', textTransform: 'none', fontWeight: 'bold' }}
                    loadingPosition="start"
                    size="large"
                    loading={loading}>
                    Iniciar sesión
                  </LoadingButton>
                </Grid>
                <Grid item xs={10} textAlign="center">
                  <Typography variant="caption" color="primary">
                    <Link
                      to="/register"
                      style={{
                        textDecoration: 'none',
                        width: '100%',
                      }}>
                      <Button
                        sx={{ textTransform: 'none', fontWeight: 'bold' }}
                        fullWidth
                        variant="outlined"
                        size="large">
                        Registrarse
                      </Button>
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={10} textAlign="center" sx={{ mb: 4 }}>
                  <Typography variant="caption" color="primary">
                    <Button
                      onClick={handleSamlLogin}
                      sx={{ textTransform: 'none', fontWeight: 'bold' }}
                      fullWidth
                      variant="outlined"
                      size="large">
                      Iniciar sesión con Active Directory
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
}

LoginForm.propTypes = {
  goToHome: PropTypes.func.isRequired,
  controls: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  onChangeText: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

LoginForm.defaultProps = {
  loading: false,
  error: false,
};

export default LoginForm;
