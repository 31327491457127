/* eslint-disable no-param-reassign  */
/* eslint-disable no-restricted-syntax  */
import { useCallback, useState } from 'react';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';

const objectTarget = (target, date) => ({
  file: target.files && target.files[0],
  checkbox: target.checked,
  date,
  time: date,
});

const getEventValue = ({ target }, date) => {
  const formatedObject = objectTarget(target, date);

  const { type } = target;

  if (Object.keys(formatedObject).includes(type)) {
    return objectTarget[type];
  }

  return target.value;
};

/* eslint-disable complexity */
function deconstructEvent(event, date) {
  let id;
  let value;
  if (event.type === 'change') {
    event.persist();
    value = getEventValue(event, date);
    if (value === undefined) value = event.target.checked;

    id = event.target.type ? event.target.id : event.currentTarget.id;
  } else if (event.type === 'click') {
    id = event.target.name;
    value = event.target.value;
  } else if (event.type === 'dropzone') {
    id = event.id;
    value = event.value;
  }

  return { id, value };
}

const initialStateForm = (validations, currentErrors = {}) => {
  const initialState = { ...currentErrors };

  Object.keys(validations).forEach((key) => {
    if (!Array.isArray(initialState[key])) {
      initialState[key] = [];
    }
  });

  return initialState;
};

const validateChangeCallback = ({ actions, options }) =>
  actions?.controlsChanged == null && options?.onChange == null;

const callValidate = ({ options, validate, id, value }) => {
  // Either validate on change or clear errors if it is valid
  const { validateOnChange } = options;

  if (validateOnChange) {
    validate(id, value);
  }

  return validateOnChange;
};

const callSetErrors = ({ allowSetErrors, setErrors, id }) => {
  if (allowSetErrors) {
    setErrors((newErrors) => ({ ...newErrors, [id]: [] }));
  }
};

const handleChangeValidation = ({ options, validate, isValid, id, value, setErrors }) => {
  const validateOnChange = callValidate({ options, validate, id, value });

  const isContentValid = isValid(id, value);
  const allowSetErrors = !validateOnChange && isContentValid;

  callSetErrors({ allowSetErrors, setErrors, id });
};

const assignDefault = ({ newErrors, id }) => {
  if (!newErrors[id]) {
    newErrors[id] = [];
  }
};

const validationMessage = (validation) => validation.message ?? 'Campo inválido';

const pushNewError = ({ validation, value, isValidValue, newErrors, id }) => {
  let isValid = isValidValue;

  if (!validation.check(value)) {
    isValid = false;
    assignDefault({ newErrors, id });
    newErrors.push(validationMessage(validation));
  }

  return { isValid, newErrors };
};

const fillErrors = ({ options, value, id }) => {
  const newErrors = [];
  let isValidValue = true;

  for (const validation of options.validations[id]) {
    const { isValid } = pushNewError({
      validation,
      value,
      isValidValue,
      newErrors,
      id,
    });

    isValidValue = isValid;
  }

  return { newErrors, isValidValue };
};

const setNewErrors = ({ value, validation, isValid, newErrors, id }) => {
  if (!validation.check(value)) {
    isValid = false;

    assignDefault({ newErrors, id });

    newErrors[id].push(validationMessage(validation));
  }

  return { isValid };
};

const fillAllErrors = ({ newErrors, options, value, id }) => {
  let isValid = true;

  for (const validation of options.validations[id]) {
    const { isValid: validToReturn } = setNewErrors({
      newErrors,
      validation,
      value,
      id,
      isValid,
    });
    isValid = validToReturn;
  }

  return { isValid };
};

const handleChange = ({ hasControlsChanged, actions, options, id, value, errors, setErrors }) => {
  if (hasControlsChanged) {
    actions.controlsChanged({ [id]: value });
  } else {
    options.onChange(id, value);
  }
  const newErrors = { ...errors };
  // Clear errors because fillAllErrors recreate all
  newErrors[id] = [];

  fillAllErrors({ newErrors, options, value, id });

  setErrors(newErrors);
};

const runOnChangeActions = ({
  id,
  value,
  options = {},
  validate,
  isValid,
  setErrors,
  hasControlsChanged,
  actions,
  errors,
}) => {
  if (id == null) throw new Error('Input id is missing');

  handleChangeValidation({ options, validate, isValid, id, value, setErrors });

  handleChange({
    hasControlsChanged,
    actions,
    options,
    id,
    value,
    errors,
    setErrors,
  });
};

const useForm = (controls, actions, options) => {
  const [errors, setErrors] = useState(initialStateForm(options.validations));

  if (validateChangeCallback({ actions, options })) {
    throw new Error('Parameters are missing change callback');
  }

  const isValid = useCallback(
    (id, value) =>
      options.validations[id] == null ||
      options.validations[id].every((validation) => validation.check(value)),
    [options],
  );

  const validate = useCallback(
    (id, value) => {
      // let isValidValue = true;
      // const newErrors = [];

      // for (const validation of options.validations[id]) {
      //   if (!validation.check(value)) {
      //     isValidValue = false;
      //     if (newErrors[id] == null) {
      //       newErrors[id] = [];
      //     }
      //     newErrors.push(validation.message ?? 'Campo inválido');
      //   }
      // }

      const { newErrors, isValidValue } = fillErrors({ options, value, id });

      setErrors((state) => ({
        ...state,
        [id]: newErrors.length === 0 ? [] : newErrors,
      }));

      return isValidValue;
    },
    [options.validations, controls],
  );

  const validateAll = useCallback(() => {
    const ids = Object.keys(options.validations);
    let isValidForm = true;
    const newErrors = initialStateForm(options.validations);

    ids.forEach((id) => {
      const value = controls[id];
      const { isValid: validField } = fillAllErrors({ newErrors, options, value, id });
      if (isValidForm) {
        isValidForm = validField;
      }
    });

    setErrors(newErrors);

    return isValidForm;
  }, [options.validations, controls]);

  const onChange = useCallback(
    (event) => {
      const hasControlsChanged = actions && 'controlsChanged' in actions;
      const { id, value } = deconstructEvent(event, null); // TODO: Pass date

      // if (id == null) {
      //   throw new Error('Input is missing id');
      // }
      //
      // // Either validate on change or clear errors if it is valid
      // if (options?.validateOnChange) {
      //   validate(id, value);
      // } else if (isValid(id, value)) {
      //   setErrors((newErrors) => ({ ...newErrors, [id]: [] }));
      // }
      //
      // if (hasControlsChanged) {
      //   actions.controlsChanged({ [id]: value });
      // } else {
      //   options.onChange(id, value);
      // }
      const newErrors = initialStateForm(options.validations, errors);

      runOnChangeActions({
        id,
        value,
        options,
        isValid,
        setErrors,
        hasControlsChanged,
        actions,
        errors: newErrors,
      });
    },
    [options, actions, isValid, validate],
  );

  const onSubmit = useCallback(
    (callback) => {
      const valid = validateAll();

      if (valid) {
        callback();
      } else {
        defaultErrorToast({ message: 'Faltan datos por completar' });
      }
    },
    [validateAll],
  );

  return { errors, onChange, onSubmit, validateAll, validate };
};

export default useForm;
