import { createReducer } from '@reduxjs/toolkit';
import OperatorRequestDetailTypes from 'content/features/operator-request-detail/operatorRequestDetail.types';

const initialState = {
  loaders: {
    initial: false,
    changeRequest: false,
  },
  controls: {
    error: false,
    requestChanged: false,
    requestStatusControl: '',
    requestStatusControlError: false,
    checkOptions: {},
    checkError: false,
    observations: '',
  },
  initialData: {
    requestStatusValues: [],
    objectionReasons: [],
  },
  request: {
    id: '',
    operatorUserId: '',
    // vehicle section
    vehicleVin: '',
    vehicleBrand: '',
    vehicleModel: '',
    vehicleYear: '',
    vehicleLicensePlate: '',
    // beneficiary section
    personTaxNumber: '',
    personNames: '',
    personPaternalSurname: '',
    personMaternalSurname: '',
    personAddress: '',
    personRegion: '',
    personCity: '',
    personCommune: '',
    personPhone: '',
    personEmail: '',
    // payment method section
    accountPaymentMethod: '',
    accountHolderName: '',
    accountHolderTaxNumber: '',
    accountBank: '',
    accountType: '',
    accountNumber: '',
    // personal documents section
    frontIdCard: {
      src: '',
      type: '',
    },
    backIdCard: {
      src: '',
      type: '',
    },
    carInscription: {
      src: '',
      type: '',
    },
    // request status section
    requestStatus: '',
    objectionReasonsSelected: [],
    objectionObservations: '',
  },
};

const OperatorRequestDetailReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(OperatorRequestDetailTypes.FETCH_REQUEST, (state) => {
      state.loaders.initial = true;
      state.controls.error = false;
    })
    .addCase(OperatorRequestDetailTypes.FETCH_REQUEST_FAIL, (state) => {
      state.loaders.initial = false;
      state.controls.error = true;
    })
    .addCase(OperatorRequestDetailTypes.FETCH_REQUEST_SUCCESS, (state, action) => {
      state.loaders.initial = false;
      state.request = action.payload.request;
      action.payload.initialData.objectionReasons.forEach((row) => {
        const key = `reason${row.value}`;
        state.controls.checkOptions[key] = false;
      });
      state.initialData.requestStatusValues = action.payload.initialData.requestStatus;
      state.initialData.objectionReasons = action.payload.initialData.objectionReasons;
      state.controls.requestStatusControl = action.payload.request.requestStatus;
    })
    .addCase(OperatorRequestDetailTypes.SET_CONTROL, (state, action) => {
      state.controls[action.payload.name] = action.payload.value;
    })
    .addCase(OperatorRequestDetailTypes.TOGGLE_CHECK, (state, action) => {
      state.controls.checkOptions[action.payload.name] = action.payload.value;
    })
    .addCase(OperatorRequestDetailTypes.ACCEPT_REQUEST, (state) => {
      state.loaders.changeRequest = true;
      state.controls.requestChanged = false;
    })
    .addCase(OperatorRequestDetailTypes.ACCEPT_REQUEST_FAIL, (state) => {
      state.loaders.changeRequest = false;
      state.controls.requestChanged = false;
    })
    .addCase(OperatorRequestDetailTypes.ACCEPT_REQUEST_SUCCESS, (state) => {
      state.loaders.changeRequest = false;
      state.controls.requestChanged = true;
    })
    .addCase(OperatorRequestDetailTypes.OBJECT_REQUEST, (state) => {
      state.loaders.changeRequest = true;
      state.controls.requestChanged = false;
    })
    .addCase(OperatorRequestDetailTypes.OBJECT_REQUEST_FAIL, (state) => {
      state.loaders.changeRequest = false;
      state.controls.requestChanged = false;
    })
    .addCase(OperatorRequestDetailTypes.OBJECT_REQUEST_SUCCESS, (state) => {
      state.loaders.changeRequest = false;
      state.controls.requestChanged = true;
    })
    .addCase(OperatorRequestDetailTypes.REJECT_REQUEST, (state) => {
      state.loaders.changeRequest = true;
      state.controls.requestChanged = false;
    })
    .addCase(OperatorRequestDetailTypes.REJECT_REQUEST_FAIL, (state) => {
      state.loaders.changeRequest = false;
      state.controls.requestChanged = false;
    })
    .addCase(OperatorRequestDetailTypes.REJECT_REQUEST_SUCCESS, (state) => {
      state.loaders.changeRequest = false;
      state.controls.requestChanged = true;
    })
    .addCase(OperatorRequestDetailTypes.PAY_REQUEST, (state) => {
      state.loaders.changeRequest = true;
      state.controls.requestChanged = false;
    })
    .addCase(OperatorRequestDetailTypes.PAY_REQUEST_FAIL, (state) => {
      state.loaders.changeRequest = false;
      state.controls.requestChanged = false;
    })
    .addCase(OperatorRequestDetailTypes.PAY_REQUEST_SUCCESS, (state) => {
      state.loaders.changeRequest = false;
      state.controls.requestChanged = true;
    })
    .addCase(OperatorRequestDetailTypes.CLEAR_SCREEN_DATA, (state) => {
      state.loaders = initialState.loaders;
      state.controls = initialState.controls;
      state.initialData = initialState.initialData;
      state.request = initialState.request;
    });
});

export default OperatorRequestDetailReducer;
