import React, { useEffect, useCallback } from 'react';

/* Material UI */
import { CardContent, Snackbar, CardMedia, Card, Grid, Alert } from '@mui/material';

/* React Router */
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Project */
import { getParameterByName } from 'utils/helper';
import Paths from 'utils/paths';
import {
  RecoveryForm,
  RecoverySelector,
  recoveryStyles,
  UpdatePasswordForm,
} from 'content/features/authentication';
import {
  resetState,
  sendRecoverPasswordEmail,
  setControl,
  setControls,
  toggleError,
  toggleSuccess,
} from 'content/features/authentication/recovery/recovery.actions';

/* Public */
const deloitteDesktop = `${window.location.origin}/images/deloitte-desktop-negro.png`;

function RecoveryPassword() {
  const states = useSelector(RecoverySelector.page);

  const { controls, successChangePassword, message, error, loading } = states;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathLogin = Paths.publics[1].path;

  useEffect(() => {
    const token = getParameterByName('token', location.search);
    const email = getParameterByName('email', location.search);

    dispatch(setControls({ token, email }));
    return () => {
      dispatch(resetState());
    };
  }, [location.search]);

  const onChangeText = useCallback((name, value) => {
    dispatch(setControl({ name, value }));
  }, []);

  const togglePageError = () => {
    dispatch(toggleError());
  };

  const togglePageSuccess = () => {
    dispatch(toggleSuccess());
  };

  const goToHome = () => navigate('/');

  return (
    <div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100vh', backgroundColor: '#E8EAE9' }}>
        <Grid item md={4} lg={4} xs={12}>
          <Card sx={{ boxShadow: 0, borderRadius: 6, maxWidth: '500px', maxHeight: '620px' }}>
            <CardContent>
              <CardMedia
                onClick={goToHome}
                component="img"
                sx={{
                  ...recoveryStyles.image,
                  cursor: 'pointer',
                  objectFit: 'contain',
                  maxWidth: '250px',
                  margin: 'auto',
                  mt: 4,
                }}
                image={deloitteDesktop}
                title="Deloitte"
              />
              <Routes>
                <Route
                  exact
                  path="/password"
                  element={
                    <UpdatePasswordForm
                      onChangeText={onChangeText}
                      password={controls.password}
                      verifyPassword={controls.verifyPassword}
                      styles={recoveryStyles}
                      loading={loading}
                      controls={controls}
                      navigate={navigate}
                    />
                  }
                />
                <Route
                  exact
                  path="/"
                  element={
                    <RecoveryForm
                      onChangeText={onChangeText}
                      email={controls.email}
                      styles={recoveryStyles}
                      loading={loading}
                      controls={controls}
                      navigate={navigate}
                      sendRecoverPasswordEmail={sendRecoverPasswordEmail}
                    />
                  }
                />
              </Routes>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={error}
        autoHideDuration={3000}
        onClose={togglePageError}>
        <Alert onClose={togglePageError} severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={successChangePassword}
        autoHideDuration={3000}
        onClose={() => {
          if (successChangePassword) {
            navigate(pathLogin);
          }
        }}>
        <Alert onClose={togglePageSuccess} severity="success">
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default RecoveryPassword;
