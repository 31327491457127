import React from 'react';
import { Fab } from '@mui/material';
import { AddTask } from '@mui/icons-material';

function NewAssignmentButton({ onClick }) {
  return (
    <Fab
      color="primary"
      variant="extended"
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
      }}
      onClick={onClick}>
      Asignar
      <AddTask sx={{ ml: 1 }} />
    </Fab>
  );
}

export default NewAssignmentButton;
