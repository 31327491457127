import { spawn, takeLatest, put } from 'redux-saga/effects';

import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import apiRequest from 'utils/api';
import {
  fetchBeneficiaryRequestsList,
  fetchBeneficiaryRequestsListFail,
  fetchBeneficiaryRequestsListSuccess,
} from './beneficiaryRequests.actions';

function* list() {
  yield takeLatest(
    fetchBeneficiaryRequestsList,
    function* fetchBeneficiaryRequestsListSaga(action) {
      const { page, pageSize } = action.payload;
      const response = yield apiRequest(
        `api/v1/conciliation-request/beneficiary/requests?page=${page}&pageSize=${pageSize}`,
        {
          method: 'get',
        },
      );
      if (response.error) {
        defaultErrorToast({ message: response.message });
        yield put(fetchBeneficiaryRequestsListFail());
      } else {
        defaultSuccessToast({ message: 'Solicitudes cargadas exitosamente.' });
        yield put(fetchBeneficiaryRequestsListSuccess(response));
      }
    },
  );
}

export default function* BeneficiaryRequestsSaga() {
  yield spawn(list);
}
