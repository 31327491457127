import React from 'react';
import Paper from '@mui/material/Paper';
import { Stack, TablePagination } from '@mui/material';
import OperatorDesktopTable from 'content/features/operator-requests/components/OperatorDesktopTable';
import { useDispatch, useSelector } from 'react-redux';
import { setPagination } from 'content/features/operator-requests/operatorRequests.actions';

function OperatorRequestList({ rows }) {
  const dispatch = useDispatch();
  const { page, pageSize, totalRequests } = useSelector(
    (state) => state.operatorRequests.pagination,
  );

  const handleChangePage = (event, newPage) => {
    dispatch(setPagination({ name: 'page', value: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setPagination({ name: 'pageSize', value: parseInt(event.target.value, 10) }));
  };

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <OperatorDesktopTable rows={rows} />
      <Stack alignItems="center">
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Filas por página"
          labelDisplayedRows={({ from, to, count }) => `${from}—${to} de ${count}`}
          component="div"
          count={totalRequests}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>
    </Paper>
  );
}

export default OperatorRequestList;
