import React, { useEffect } from 'react';
import { Container, Grid, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import BeneficiaryRequestSkeleton from 'content/features/beneficiary-requests/components/BeneficiaryRequestsSkeleton';
import MainTitle from 'content/shared/MainTitle/MainTitle';
import { AccountCircle } from '@mui/icons-material';
import UserList from 'content/features/user-maintainer/components/UserList';

import { formatLettersAndSpaces, formatOnlyNumbers } from 'utils/helper';
import {
  clearFilters,
  fetchUserList,
  setControl,
} from 'content/features/user-maintainer/userMaintainer.actions';
import UserFilter from 'content/features/user-maintainer/components/UserFilter';

// eslint-disable-next-line complexity
function UserMaintainer() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const rows = useSelector((state) => state.userMaintainer.users);
  const dispatch = useDispatch();
  const { loaders, pagination, controls } = useSelector((state) => state.userMaintainer);

  useEffect(() => {
    dispatch(fetchUserList({ controls, pagination }));
  }, [pagination.page, pagination.pageSize]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    const actions = {
      name: (val) => formatLettersAndSpaces(val),
      userId: (val) => formatOnlyNumbers(val),
    };

    const action = actions[name];
    const newValue = action ? action(value) : value;

    dispatch(setControl({ name, value: newValue }));
  };

  const handleClear = () => {
    dispatch(clearFilters());
    const clearFiltersData = {
      userId: '',
      name: '',
    };
    dispatch(fetchUserList({ controls: clearFiltersData, pagination }));
  };

  const handleSearch = () => {
    dispatch(fetchUserList({ controls, pagination }));
  };

  if (loaders.initial) {
    return (
      <Container
        maxWidth
        style={{ paddingLeft: isMobile ? '' : '6rem', paddingRight: isMobile ? '' : '6rem' }}>
        <BeneficiaryRequestSkeleton />
      </Container>
    );
  }

  return (
    <Container
      maxWidth
      style={{ paddingLeft: isMobile ? '' : '6rem', paddingRight: isMobile ? '' : '6rem' }}>
      <Grid container justifyContent="center" alignItems="center" spacing={4}>
        <MainTitle
          title="Mantenedor de usuarios"
          subtitle="Administra a los usuarios de la plataforma. Puedes agregar nuevos, editar a los ya existentes o eliminar si es necesario."
          icon={<AccountCircle sx={{ fontSize: 40, marginRight: 1, color: '#86BC25' }} />}
        />
        <Grid item xs={12}>
          <UserFilter
            controls={controls}
            onChange={handleChange}
            handleClear={handleClear}
            handleSearch={handleSearch}
          />
        </Grid>
        <Grid item xs={12}>
          <UserList rows={rows} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default UserMaintainer;
