import React from 'react';

import { Container, Grid, useMediaQuery } from '@mui/material';
import { DescriptionRounded } from '@mui/icons-material';

/* Project */
import MainTitle from 'content/shared/MainTitle/MainTitle';
import Document from 'content/features/terms-and-conditions/components/Document';
import {
  document1,
  document2,
  document3,
} from '../../features/terms-and-conditions/components/termsAndConditionsData';

function TermsAndConditions() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Container
      maxWidth
      style={{ paddingLeft: isMobile ? '' : '6rem', paddingRight: isMobile ? '' : '6rem' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MainTitle
            title="Términos y condiciones"
            subtitle="Revisa aquí los términos y condiciones de nuestro sistema de conciliación."
            icon={<DescriptionRounded sx={{ fontSize: 40, marginRight: 1, color: '#86BC25' }} />}
          />
          <Document data={document1} />
          <Document data={document2} />
          <Document data={document3} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default TermsAndConditions;
