import React from 'react';

import { Grid, Typography } from '@mui/material';
import Section from './Section';

function Document({ data }) {
  // eslint-disable-next-line complexity
  const convertToRomanNumerals = (number) => {
    if (typeof number !== 'number' || number < 1 || number > 3999) {
      throw new Error('El número debe ser un entero positivo entre 1 y 3999.');
    }

    const romanNumerals = [
      { value: 1000, numeral: 'M' },
      { value: 900, numeral: 'CM' },
      { value: 500, numeral: 'D' },
      { value: 400, numeral: 'CD' },
      { value: 100, numeral: 'C' },
      { value: 90, numeral: 'XC' },
      { value: 50, numeral: 'L' },
      { value: 40, numeral: 'XL' },
      { value: 10, numeral: 'X' },
      { value: 9, numeral: 'IX' },
      { value: 5, numeral: 'V' },
      { value: 4, numeral: 'IV' },
      { value: 1, numeral: 'I' },
    ];

    let result = '';
    let remainingNumber = number;

    romanNumerals.forEach((roman) => {
      while (remainingNumber >= roman.value) {
        result += roman.numeral;
        remainingNumber -= roman.value;
      }
    });

    return result;
  };

  return (
    <Grid item xs={12} mt={4}>
      <Typography
        sx={{
          fontSize: '20px',
          fontWeight: 700,
          lineHeight: '24px',
          mb: 3,
        }}>
        {data.title}
      </Typography>
      {data.content.map((item, index) => (
        <Section
          key={`section-${index + 1}`}
          title={`${convertToRomanNumerals(index + 1)}. ${item.subtitle}`}
          paragraph={item.paragraph}
        />
      ))}
    </Grid>
  );
}

export default Document;
