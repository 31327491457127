import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const EditDocument = React.forwardRef((props, ref) => (
  <SvgIcon
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    height="40px"
    viewBox="0 -960 960 960"
    width="40px"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}>
    <path d="M226.67-80q-27 0-46.84-19.83Q160-119.67 160-146.67v-666.66q0-27 19.83-46.84Q199.67-880 226.67-880H560l240 240v134.67q-19 3.66-36.33 13Q746.33-483 732-468.67L493.33-231v151H226.67ZM560-80v-123l221-220q9-9 20-13t22-4q12 0 23 4.5t20 13.5l37 37q8.67 9 12.83 20 4.17 11 4.17 22t-4.33 22.5q-4.34 11.5-13.34 20.5L683-80H560Zm263-224 37-39-37-37-38 38 38 38ZM520-600h213.33L520-813.33 733.33-600 520-813.33V-600Z" />
  </SvgIcon>
));

export default EditDocument;
