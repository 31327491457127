import { put, spawn, takeLatest } from 'redux-saga/effects';

import apiRequest from 'utils/api';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import {
  fetchUserList,
  fetchUserListFail,
  fetchUserListSuccess,
  recoverUserPwd,
  recoverUserPwdFail,
  recoverUserPwdSuccess,
  updateUser,
  updateUserFail,
  updateUserSuccess,
} from './userMaintainer.actions';

function craftParams(controls, pagination) {
  const { page, pageSize } = pagination;
  const { userId, rut, name } = controls;
  return `api/v1/users?page=${page}&pageSize=${pageSize}&userId=${userId}&name=${name}&rut=${rut}`;
}

function* list() {
  yield takeLatest(fetchUserList, function* fetchUserListSaga(action) {
    const { controls, pagination } = action.payload;
    const response = yield apiRequest(craftParams(controls, pagination), { method: 'get' });

    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(fetchUserListFail());
    } else {
      yield put(fetchUserListSuccess(response));
    }
  });
}

function* updateEnabled() {
  yield takeLatest(updateUser, function* updateUserSaga(action) {
    const { id, name, type, isEnabled, controls, pagination } = action.payload;
    const response = yield apiRequest(`api/v1/users/${id}`, {
      method: 'put',
      'Content-Type': 'application/json',
      body: JSON.stringify({ name, type, isEnabled }),
    });

    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(updateUserFail());
    } else {
      defaultSuccessToast({
        message: `Usuario ${isEnabled ? 'habilitado' : 'deshabilitado'} exitosamente.`,
      });
      yield put(updateUserSuccess(response));
      yield put(fetchUserList({ controls, pagination }));
    }
  });
}

function* recoverUserPassword() {
  yield takeLatest(recoverUserPwd, function* updateUserSaga(action) {
    const { email } = action.payload;

    const response = yield apiRequest(`api/v1/auth/recover-password`, {
      method: 'post',
      'Content-Type': 'application/json',
      body: JSON.stringify({ email }),
    });

    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(recoverUserPwdFail());
    } else {
      defaultSuccessToast({
        message: `Correo de recuperación enviado a ${email}`,
      });
      yield put(recoverUserPwdSuccess(response));
    }
  });
}

export default function* UserMaintainerSaga() {
  yield spawn(list);
  yield spawn(updateEnabled);
  yield spawn(recoverUserPassword);
}
