import { Grid, Typography } from '@mui/material';
import React from 'react';

function SectionTitle({ title, sx }) {
  return (
    <Grid item xs={12} sx={{ ...sx }}>
      <Typography fontWeight={600} sx={{ fontSize: '24px' }}>
        {title}
      </Typography>
    </Grid>
  );
}

export default SectionTitle;
