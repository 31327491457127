import React from 'react';

/* Material UI */
import { Typography, Grid, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

/* React Redux */
import { useDispatch } from 'react-redux';

/* Project */
import { validateEmail } from 'utils/functions';
import useForm from 'hooks/useForm';

function RecoveryForm(props) {
  const { styles, onChangeText, controls, navigate, email, loading, sendRecoverPasswordEmail } =
    props;

  const dispatch = useDispatch();

  const { onChange, onSubmit, errors } = useForm(controls, null, {
    onChange: onChangeText,
    validations: {
      email: [
        {
          check: (value) => value?.trim()?.length > 0,
          message: 'Correo electrónico requerido',
        },
        {
          check: (value) => validateEmail(value).check,
          message: 'Correo electrónico inválido',
        },
      ],
    },
  });

  const onClickVerifyEmail = (event) => {
    event.preventDefault();
    onSubmit(() => dispatch(sendRecoverPasswordEmail({ email, navigate })));
  };

  return (
    <form onSubmit={onClickVerifyEmail}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10} textAlign="center" sx={{ mt: 3 }}>
          <Typography
            variant="caption"
            sx={{
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: '32.68px',
            }}>
            Recuperar contraseña
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <TextField
            sx={styles.field}
            label="Correo electrónico"
            key="email"
            id="email"
            name="email"
            value={email}
            onChange={onChange}
            fullWidth
            error={!!errors.email[0]}
            helperText={errors.email[0]}
          />
        </Grid>
        <Grid item xs={10} textAlign="justify">
          <Typography variant="body1" component="div" sx={{ fontSize: '16px', fontWeight: 400 }}>
            Se enviará un correo electrónico con un <strong>link</strong> al que deberás ingresar
            para cambiar tu contraseña.
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <LoadingButton
            loading={loading}
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            color="primary"
            sx={{ textTransform: 'none', fontWeight: 'bold' }}>
            Enviar link de recuperación
          </LoadingButton>
        </Grid>
        <Grid item xs={10}>
          <Button
            onClick={() => navigate(-1)}
            sx={{ textTransform: 'none', fontWeight: 'bold' }}
            fullWidth
            variant="outlined"
            size="large">
            Volver
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default RecoveryForm;
