import React from 'react';
import { Card, CardMedia, Grid, Typography } from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';

function CustomDropzoneView({ file, fileName, handleOpenModal }) {
  return (
    <Grid
      component="div"
      container
      justifyContent="center"
      alignItems="center"
      style={{
        border: '2px dashed #002855BF',
        borderRadius: '5px',
        textAlign: 'center',
        padding: '20px',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(134, 188, 37, 0.1)',
      }}>
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          onClick={() => {
            if (file.type !== 'pdf') handleOpenModal(file.src);
          }}
          alignItems="center"
          spacing={2}>
          <Grid item xs={4} md={4}>
            {file.type === 'pdf' ? (
              <PictureAsPdf sx={{ fontSize: 70, color: 'red' }} />
            ) : (
              <Card
                sx={{
                  align: 'center',
                  m: 0,
                  p: 0,
                  maxWidth: 100,
                  cursor: 'pointer',
                }}>
                <CardMedia component="img" alt={`preview ${fileName}`} src={file.src} />
              </Card>
            )}
          </Grid>
          <Grid item xs={8} md={6}>
            {file.type === 'pdf' ? (
              <a href={file.src} target="_blank" rel="noreferrer">
                {`${fileName}.${file.type}`}
              </a>
            ) : (
              <Typography
                variant="body2"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  color: '#626262',
                }}>
                {`${fileName}.${file.type}`}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CustomDropzoneView;
