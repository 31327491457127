import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

/* React Redux */
import { useSelector, useDispatch } from 'react-redux';

/* Material UI */
import { LoginActions } from 'content/features/authentication';
import { Footer, NavBar, TemporaryDrawer } from '../../shared';

function Layout(props) {
  const { children, element } = props;

  const location = useLocation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.app.user);
  const signedByGoogle = useSelector((state) => state.app.signedByGoogle);
  const displayLogin = useSelector((state) => state.app.displayLogin);

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleLogout = () => {
    dispatch(LoginActions.logout({ user, signedByGoogle }));
  };

  const navbarRef = useRef(null);
  const [navbarHeight, setNavbarHeight] = useState(0);

  useEffect(() => {
    if (navbarRef.current) {
      setNavbarHeight(navbarRef.current.offsetHeight);
    }
  }, []);

  const extraStyles = {};

  if (location.pathname !== '/') {
    extraStyles.marginTop = `calc(${navbarHeight}px / 0.75)`;
  }

  return (
    <>
      <NavBar
        setOpenDrawer={setOpenDrawer}
        user={user}
        handleLogout={handleLogout}
        ref={navbarRef}
        displayLogin={displayLogin}
      />
      <div style={{ ...extraStyles }}>{element || children}</div>
      <TemporaryDrawer openByDefault={openDrawer} setOpenDrawer={setOpenDrawer} />
      <Footer />
    </>
  );
}

export default Layout;
