import React from 'react';

/* Material UI */
import { LoadingButton } from '@mui/lab';
import { Button, Grid, TextField, Typography } from '@mui/material';

/* React Redux */
import { useDispatch } from 'react-redux';

/* Project */
import useForm from 'hooks/useForm';
import { validateEmail, validatePassword } from 'utils/functions';
import { createUser } from '../register.actions';

function RegisterForm(props) {
  const { navigate, controls, onChangeText, loading } = props;

  const dispatch = useDispatch();

  const { email, name, password, repeatPassword } = controls;

  const { onChange, onSubmit, errors } = useForm(controls, null, {
    onChange: onChangeText,
    validations: {
      name: [
        {
          check: (value) => value?.trim()?.length > 0,
          message: 'Nombre requerido',
        },
      ],
      email: [
        {
          check: (value) => value?.trim()?.length > 0,
          message: 'Correo electrónico requerido',
        },
        {
          check: (value) => validateEmail(value).check,
          message: 'Correo electrónico inválido',
        },
      ],
      password: [
        {
          check: (value) => value?.trim()?.length > 0,
          message: 'Contraseña requerida',
        },
        {
          check: (value) => validatePassword(value).check,
          message: `${validatePassword(password).message}`,
        },
      ],
      repeatPassword: [
        {
          check: (value) => value?.trim()?.length > 0,
          message: 'Confirmación de contraseña requerida',
        },
        {
          check: (value) => validatePassword(value).check,
          message: `${validatePassword(repeatPassword).message}`,
        },
        {
          check: (value) => value === password,
          message: 'Las contraseñas deben ser iguales',
        },
      ],
    },
  });

  const submitRegisterUser = (e) => {
    e.preventDefault();
    onSubmit(() =>
      dispatch(
        createUser({
          name: controls.name,
          email: controls.email,
          password: controls.password,
          type: 'beneficiary',
          navigate,
        }),
      ),
    );
  };

  return (
    <form onSubmit={submitRegisterUser}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={10} textAlign="center" sx={{ mt: 3 }}>
          <Typography
            variant="caption"
            sx={{
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: '32.68px',
            }}>
            Crear cuenta
          </Typography>
        </Grid>

        <Grid item xs={10}>
          <TextField
            label="Nombre"
            key="name"
            id="name"
            name="name"
            value={name}
            onChange={onChange}
            fullWidth
            error={!!errors.name[0]}
            helperText={errors.name[0]}
          />
        </Grid>

        <Grid item xs={10}>
          <TextField
            label="Correo electrónico"
            key="email"
            id="email"
            name="email"
            value={email}
            onChange={onChange}
            fullWidth
            error={!!errors.email[0]}
            helperText={errors.email[0]}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            label="Contraseña"
            key="password"
            id="password"
            name="password"
            value={password}
            onChange={onChange}
            type="password"
            fullWidth
            error={!!errors.password[0]}
            helperText={errors.password[0]}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            label="Repetir contraseña"
            key="repeatPassword"
            id="repeatPassword"
            name="repeatPassword"
            value={repeatPassword}
            type="password"
            onChange={onChange}
            fullWidth
            error={!!errors.repeatPassword[0]}
            helperText={errors.repeatPassword[0]}
          />
        </Grid>
        <Grid item xs={10}>
          <LoadingButton
            loading={loading}
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            color="primary"
            sx={{ textTransform: 'none', fontWeight: 'bold' }}
            disabled={
              Object.values(controls).some((value) => value === '') ||
              Object.values(errors).some((error) => error.length > 0)
            }>
            Crear cuenta
          </LoadingButton>
        </Grid>
        <Grid item xs={10}>
          <Button
            onClick={() => navigate(-1)}
            sx={{ textTransform: 'none', fontWeight: 'bold' }}
            fullWidth
            variant="outlined"
            size="large">
            Cancelar registro
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default RegisterForm;
