/* Reducers */
import AppReducer from 'content/app.reducer';
import { LoginReducer, RecoveryReducer, RegisterReducer } from 'content/features/authentication';
import { UserMaintainerReducer } from 'content/features/user-maintainer';
import { BeneficiaryRequestsReducer } from 'content/features/beneficiary-requests';
import { BeneficiaryRequestReducer } from 'content/features/beneficiary-request';
import { BeneficiaryRequestDetailReducer } from 'content/features/beneficiary-request-detail';
import { OperatorRequestsReducer } from 'content/features/operator-requests';
import { OperatorRequestDetailReducer } from 'content/features/operator-request-detail';
import { BeneficiaryAmendmentReducer } from 'content/features/beneficiary-amendment';

const MainReducer = {
  app: AppReducer,
  login: LoginReducer,
  register: RegisterReducer,
  recovery: RecoveryReducer,
  userMaintainer: UserMaintainerReducer,
  beneficiaryRequests: BeneficiaryRequestsReducer,
  beneficiaryRequest: BeneficiaryRequestReducer,
  beneficiaryRequestDetail: BeneficiaryRequestDetailReducer,
  operatorRequests: OperatorRequestsReducer,
  operatorRequestDetail: OperatorRequestDetailReducer,
  beneficiaryAmendment: BeneficiaryAmendmentReducer,
};

export default MainReducer;
