export const document1 = {
  title: 'Documento 1',
  content: [
    {
      subtitle: 'Lorem Ipsum',
      paragraph:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut nisi sed erat hendrerit porta. Aenean non tincidunt odio, ut interdum velit. Phasellus feugiat auctor rhoncus. Cras vitae bibendum nibh, vitae porta eros. Donec in tellus eu tellus mattis gravida vitae eu libero. Vestibulum vitae metus nisi. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer nisi odio, porttitor vel faucibus non, egestas a lorem. Nam interdum lobortis quam sit amet tempor.',
    },
    {
      subtitle: 'Lorem Ipsum',
      paragraph:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut nisi sed erat hendrerit porta. Aenean non tincidunt odio, ut interdum velit. Phasellus feugiat auctor rhoncus. Cras vitae bibendum nibh, vitae porta eros. Donec in tellus eu tellus mattis gravida vitae eu libero. Vestibulum vitae metus nisi. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer nisi odio, porttitor vel faucibus non, egestas a lorem. Nam interdum lobortis quam sit amet tempor.',
    },
    {
      subtitle: 'Lorem Ipsum',
      paragraph:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut nisi sed erat hendrerit porta. Aenean non tincidunt odio, ut interdum velit. Phasellus feugiat auctor rhoncus. Cras vitae bibendum nibh, vitae porta eros. Donec in tellus eu tellus mattis gravida vitae eu libero. Vestibulum vitae metus nisi. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer nisi odio, porttitor vel faucibus non, egestas a lorem. Nam interdum lobortis quam sit amet tempor.',
    },
  ],
};

export const document2 = {
  title: 'Documento 2',
  content: [
    {
      subtitle: 'Lorem Ipsum',
      paragraph:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut nisi sed erat hendrerit porta. Aenean non tincidunt odio, ut interdum velit. Phasellus feugiat auctor rhoncus. Cras vitae bibendum nibh, vitae porta eros. Donec in tellus eu tellus mattis gravida vitae eu libero. Vestibulum vitae metus nisi. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer nisi odio, porttitor vel faucibus non, egestas a lorem. Nam interdum lobortis quam sit amet tempor.',
    },
    {
      subtitle: 'Lorem Ipsum',
      paragraph:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut nisi sed erat hendrerit porta. Aenean non tincidunt odio, ut interdum velit. Phasellus feugiat auctor rhoncus. Cras vitae bibendum nibh, vitae porta eros. Donec in tellus eu tellus mattis gravida vitae eu libero. Vestibulum vitae metus nisi. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer nisi odio, porttitor vel faucibus non, egestas a lorem. Nam interdum lobortis quam sit amet tempor.',
    },
    {
      subtitle: 'Lorem Ipsum',
      paragraph:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut nisi sed erat hendrerit porta. Aenean non tincidunt odio, ut interdum velit. Phasellus feugiat auctor rhoncus. Cras vitae bibendum nibh, vitae porta eros. Donec in tellus eu tellus mattis gravida vitae eu libero. Vestibulum vitae metus nisi. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer nisi odio, porttitor vel faucibus non, egestas a lorem. Nam interdum lobortis quam sit amet tempor.',
    },
  ],
};

export const document3 = {
  title: 'Documento 3',
  content: [
    {
      subtitle: 'Lorem Ipsum',
      paragraph:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut nisi sed erat hendrerit porta. Aenean non tincidunt odio, ut interdum velit. Phasellus feugiat auctor rhoncus. Cras vitae bibendum nibh, vitae porta eros. Donec in tellus eu tellus mattis gravida vitae eu libero. Vestibulum vitae metus nisi. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer nisi odio, porttitor vel faucibus non, egestas a lorem. Nam interdum lobortis quam sit amet tempor.',
    },
    {
      subtitle: 'Lorem Ipsum',
      paragraph:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut nisi sed erat hendrerit porta. Aenean non tincidunt odio, ut interdum velit. Phasellus feugiat auctor rhoncus. Cras vitae bibendum nibh, vitae porta eros. Donec in tellus eu tellus mattis gravida vitae eu libero. Vestibulum vitae metus nisi. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer nisi odio, porttitor vel faucibus non, egestas a lorem. Nam interdum lobortis quam sit amet tempor.',
    },
    {
      subtitle: 'Lorem Ipsum',
      paragraph:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut nisi sed erat hendrerit porta. Aenean non tincidunt odio, ut interdum velit. Phasellus feugiat auctor rhoncus. Cras vitae bibendum nibh, vitae porta eros. Donec in tellus eu tellus mattis gravida vitae eu libero. Vestibulum vitae metus nisi. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer nisi odio, porttitor vel faucibus non, egestas a lorem. Nam interdum lobortis quam sit amet tempor.',
    },
  ],
};
