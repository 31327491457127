import { spawn, takeLatest, put } from 'redux-saga/effects';

import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import apiRequest from 'utils/api';
import { setLoader } from 'content/app.actions';
import {
  createBeneficiaryAmendment,
  createBeneficiaryAmendmentFail,
  createBeneficiaryAmendmentSuccess,
  updateBeneficiaryAmendment,
  updateBeneficiaryAmendmentFail,
  updateBeneficiaryAmendmentSuccess,
  fetchFormInitialData,
  fetchFormInitialDataFail,
  fetchFormInitialDataSuccess,
  fetchRequest,
  fetchRequestFail,
  fetchRequestSuccess,
} from 'content/features/beneficiary-amendment/beneficiaryAmendment.actions';

function* list() {
  yield takeLatest(fetchFormInitialData, function* fetchFormInitialDataSaga() {
    const response = yield apiRequest('api/v1/conciliation-request/beneficiary/form-selects-data', {
      method: 'get',
    });
    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(fetchFormInitialDataFail());
    } else {
      defaultSuccessToast({ message: 'Datos cargados exitosamente' });
      yield put(fetchFormInitialDataSuccess(response));
    }
  });
}

function* create() {
  yield takeLatest(createBeneficiaryAmendment, function* createBeneficiaryAmendmentSaga(action) {
    yield put(setLoader('createBeneficiaryAmendmentButtonId', true));
    const { frontIdentityDocument, backIdentityDocument, carInscriptionDocument, ...form } =
      action.payload;

    delete form.frontIdCard;
    delete form.backIdCard;
    delete form.carInscription;

    const formData = new FormData();
    formData.append('frontIdentityDocument', frontIdentityDocument);
    formData.append('backIdentityDocument', backIdentityDocument);
    formData.append('carInscriptionDocument', carInscriptionDocument);
    formData.append('form', JSON.stringify(form));

    const response = yield apiRequest(
      'api/v1/conciliation-request/beneficiary/amendment',
      { method: 'post', body: formData },
      false,
    );

    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(createBeneficiaryAmendmentFail());
    } else {
      defaultSuccessToast({ message: 'Enmienda creada exitosamente.' });
      yield put(createBeneficiaryAmendmentSuccess(response));
    }
    yield put(setLoader('createBeneficiaryAmendmentButtonId', false));
  });
}

function* request() {
  yield takeLatest(fetchRequest, function* fetchRequestSaga(action) {
    const { id } = action.payload;
    const response = yield apiRequest(`api/v1/conciliation-request/beneficiary/amendment/${id}`, {
      method: 'get',
    });
    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(fetchRequestFail());
    } else {
      defaultSuccessToast({ message: 'Datos pre cargados exitosamente.' });
      yield put(fetchRequestSuccess(response));
    }
  });
}

function* update() {
  yield takeLatest(updateBeneficiaryAmendment, function* updateBeneficiaryAmendmentSaga(action) {
    yield put(setLoader('updateBeneficiaryAmendmentButtonId', true));
    const { id, frontIdentityDocument, backIdentityDocument, carInscriptionDocument, ...form } =
      action.payload;

    // Preparar FormData para enviar los documentos y el formulario
    const formData = new FormData();
    formData.append('frontIdentityDocument', frontIdentityDocument);
    formData.append('backIdentityDocument', backIdentityDocument);
    formData.append('carInscriptionDocument', carInscriptionDocument);
    formData.append('form', JSON.stringify(form));

    // Realizar la solicitud PUT para actualizar
    const response = yield apiRequest(
      `api/v1/conciliation-request/beneficiary/amendment/${id}`, // Enviar ID en el endpoint
      { method: 'put', body: formData }, // Usar método PUT
      false,
    );

    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(updateBeneficiaryAmendmentFail());
    } else {
      defaultSuccessToast({ message: 'Enmienda actualizada exitosamente.' });
      yield put(updateBeneficiaryAmendmentSuccess(response));
    }

    yield put(setLoader('updateBeneficiaryAmendmentButtonId', false));
  });
}

export default function* BeneficiaryAmendmentSaga() {
  yield spawn(list);
  yield spawn(create);
  yield spawn(request);
  yield spawn(update);
}
