import { spawn, takeLatest, put } from 'redux-saga/effects';

import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import apiRequest from 'utils/api';
import {
  fetchBeneficiaryRequestDetail,
  fetchBeneficiaryRequestDetailFail,
  fetchBeneficiaryRequestDetailSuccess,
} from './beneficiaryRequestDetail.actions';

function* request() {
  yield takeLatest(
    fetchBeneficiaryRequestDetail,
    function* fetchBeneficiaryRequestsDetailSaga(action) {
      const { id } = action.payload;
      const response = yield apiRequest(`api/v1/conciliation-request/beneficiary/request/${id}`, {
        method: 'get',
      });
      if (response.error) {
        defaultErrorToast({ message: response.message });
        yield put(fetchBeneficiaryRequestDetailFail());
      } else {
        defaultSuccessToast({ message: 'Detalle de solicitud cargada exitosamente.' });
        yield put(fetchBeneficiaryRequestDetailSuccess(response));
      }
    },
  );
}

export default function* BeneficiaryRequestDetailSaga() {
  yield spawn(request);
}
