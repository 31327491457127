import React, { useEffect } from 'react';
import { Button, Container, Grid, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Dvr } from '@mui/icons-material';
import RequestList from 'content/features/beneficiary-requests/components/RequestList';
import NewRequestButton from 'content/features/beneficiary-requests/components/NewRequestButton';
import { fetchBeneficiaryRequestsList } from 'content/features/beneficiary-requests/beneficiaryRequests.actions';
import MainTitle from 'content/shared/MainTitle/MainTitle';
import BeneficiaryRequestSkeleton from 'content/features/beneficiary-requests/components/BeneficiaryRequestsSkeleton';

// eslint-disable-next-line complexity
function BeneficiaryRequests() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rows = useSelector((state) => state.beneficiaryRequests.requests);
  const { loaders, pagination } = useSelector((state) => state.beneficiaryRequests);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    dispatch(fetchBeneficiaryRequestsList(pagination.page, pagination.pageSize));
  }, [pagination.page, pagination.pageSize]);

  const handleClick = () => {
    navigate('/requests/new');
  };

  return loaders.initial ? (
    <Container
      maxWidth
      style={{ paddingLeft: isMobile ? '' : '6rem', paddingRight: isMobile ? '' : '6rem' }}>
      <BeneficiaryRequestSkeleton />
    </Container>
  ) : (
    <Container
      maxWidth
      style={{ paddingLeft: isMobile ? '' : '6rem', paddingRight: isMobile ? '' : '6rem' }}>
      <Grid container justifyContent="center" alignItems="center" spacing={4}>
        <MainTitle
          title="Solicitudes"
          subtitle="Consulta el estado de tus solicitudes, crea nuevas, o, solo en caso de estar objetadas, solicita enmiendas a través de las opciones disponibles en cada fila."
          icon={<Dvr sx={{ fontSize: 40, marginRight: 1, color: '#86BC25' }} />}
        />

        <Grid container item xs={12} justifyContent={{ md: 'flex-end', xs: 'center' }}>
          <Button
            id="createBeneficiaryRequestButtonId"
            variant="contained"
            size="large"
            fullWidth={isMobile}
            onClick={handleClick}
            sx={{ textTransform: 'none', fontWeight: 'bold' }}>
            Ingresar nueva solicitud
          </Button>
        </Grid>
        <Grid item xs={12}>
          <RequestList rows={rows} />
        </Grid>
        <NewRequestButton onClick={handleClick} />
      </Grid>
    </Container>
  );
}

export default BeneficiaryRequests;
