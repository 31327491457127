import React from 'react';
import { Grid, Typography } from '@mui/material';

function MainTitle({ title, subtitle, icon, status }) {
  return (
    <Grid item xs={12} sx={{ paddingTop: '24px!important' }}>
      <Typography
        variant="h4"
        component="h1"
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          fontSize: '36px',
          fontWeight: 700,
          lineHeight: '49.03px',
          mb: 2,
        }}>
        {icon} {title} {status}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontSize: '16px',
          fontWeight: 300,
          lineHeight: '22px',
          letterSpacing: '0px',
          textAlign: 'left',
        }}>
        {subtitle}
      </Typography>
    </Grid>
  );
}

export default MainTitle;
