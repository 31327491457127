import { indigo, teal } from '@mui/material/colors';

/**
 * @type {import('@mui/material').ThemeOptions}
 */
const defaultTheme = {
  palette: {
    primary: {
      main: '#86BC25',
      contrastText: '#fff',
    },
    secondary: {
      main: teal[900],
    },
    title: {
      main: indigo[900],
      contrastText: '#fff',
    },
    text: {
      title: 'rgb(112, 76, 182)',
      number: 'rgb(81,82,82)',
    },
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif', // Cambiando la fuente a Open Sans
  },
  components: {
    MuiTextField: {
      defaultProps: {
        color: 'secondary',
      },
    },
    // MuiContainer: {
    //   styleOverrides: {
    //     root: {
    //       maxWidth: 'calc(100vw - 200px)',
    //       paddingLeft: '100px',
    //       paddingRight: '100px',
    //       marginRight: '0px',
    //       marginLeft: '0px',
    //       '@media (min-width: 1200px)': {
    //         paddingLeft: '0px',
    //         paddingRight: '0px',
    //         marginRight: '100px',
    //         marginLeft: '100px',
    //         maxWidth: 'calc(100vw - 200px)',
    //       },
    //       '@media (max-width: 1200px)': {
    //         marginRight: 'auto',
    //         marginLeft: 'auto',
    //         maxWidth: '100vw',
    //       },
    //       '@media (max-width: 600px)': {
    //         paddingLeft: '16px',
    //         paddingRight: '16px',
    //         marginRight: 'auto',
    //         marginLeft: 'auto',
    //         maxWidth: '100vw',
    //       },
    //     },
    //   },
    // },
  },
};

export default defaultTheme;
