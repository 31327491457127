import React from 'react';
import Paper from '@mui/material/Paper';
import { Stack, TablePagination, useMediaQuery } from '@mui/material';
import DesktopTable from 'content/features/beneficiary-requests/components/DesktopTable';
import MobileTable from 'content/features/beneficiary-requests/components/MobileTable';
import { useDispatch, useSelector } from 'react-redux';
import { setPagination } from '../beneficiaryRequests.actions';

function RequestList({ rows }) {
  const dispatch = useDispatch();
  const { page, pageSize, totalRequests } = useSelector(
    (state) => state.beneficiaryRequests.pagination,
  );

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleChangePage = (event, newPage) => {
    dispatch(setPagination({ name: 'page', value: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setPagination({ name: 'pageSize', value: parseInt(event.target.value, 10) }));
  };

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      {isMobile ? <MobileTable rows={rows} /> : <DesktopTable rows={rows} />}
      <Stack alignItems="center">
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Filas por página"
          labelDisplayedRows={({ from, to, count }) => `${from}—${to} de ${count}`}
          component="div"
          count={totalRequests}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>
    </Paper>
  );
}

export default RequestList;
