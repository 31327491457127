import React from 'react';
import { Grid, Skeleton } from '@mui/material';

function FieldSkeleton({ titleWidth = '150px', valueWidth = '261px' }) {
  return (
    <Grid container item xs={3} spacing={1}>
      <Grid item xs={12}>
        <Skeleton variant="rounded" width={titleWidth} height="12px" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" width={valueWidth} height="24px" />
      </Grid>
    </Grid>
  );
}
function BeneficiaryRequestDetailSkeleton() {
  return (
    <Grid container spacing={1} sx={{ mt: 2.5 }}>
      <Grid item xs={12} sx={{ mb: 1 }}>
        <Skeleton variant="rounded" width="280px" height="49px" />
      </Grid>
      <Grid item xs={12} sx={{ mb: 6 }}>
        <Skeleton variant="rounded" width="600px" height="22px" />
      </Grid>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Skeleton variant="rounded" width="213px" height="24px" />
      </Grid>
      <Grid item xs={12} container sx={{ mb: 8 }}>
        <FieldSkeleton />
        <FieldSkeleton titleWidth="68px" />
      </Grid>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Skeleton variant="rounded" width="213px" height="24px" />
      </Grid>
      <Grid item xs={12} container sx={{ mb: 6 }}>
        <FieldSkeleton titleWidth="53px" />
        <FieldSkeleton titleWidth="53px" />
        <FieldSkeleton titleWidth="24px" />
        <FieldSkeleton titleWidth="51px" />
      </Grid>
      <Grid item xs={12} container sx={{ mb: 6 }}>
        <FieldSkeleton titleWidth="54px" />
        <FieldSkeleton titleWidth="49px" />
        <FieldSkeleton titleWidth="41px" />
        <FieldSkeleton titleWidth="40px" />
      </Grid>
      <Grid item xs={12} container sx={{ mb: 8 }}>
        <FieldSkeleton titleWidth="107px" />
      </Grid>
      <Grid item xs={6} sx={{ mb: 3 }}>
        <Skeleton variant="rounded" width="376px" height="24px" />
      </Grid>
      <Grid item xs={6} sx={{ mb: 3 }}>
        <Skeleton variant="rounded" width="606px" height="24px" />
      </Grid>
      <Grid item xs={5} sx={{ mb: 12 }} container alignItems="center" justifyContent="flex-end">
        <Skeleton variant="rounded" width="24px" height="24px" sx={{ mr: 1 }} />
        <Skeleton variant="rounded" width="217px" height="24px" />
      </Grid>
      <Grid item xs={5} sx={{ mb: 12 }} container alignItems="center" justifyContent="flex-end">
        <Skeleton variant="rounded" width="24px" height="24px" sx={{ mr: 1 }} />
        <Skeleton variant="rounded" width="217px" height="24px" />
      </Grid>
      <Grid item xs={12} sx={{ mb: 6 }} container alignItems="center">
        <Skeleton variant="rounded" width="18px" height="18px" sx={{ mr: 1 }} />
        <Skeleton variant="rounded" width="356px" height="24px" />
      </Grid>
      <Grid item xs={6} sx={{ mb: 3 }} container>
        <Skeleton variant="rounded" width="143px" height="24px" />
      </Grid>
      <Grid item xs={6} sx={{ mb: 3 }} container justifyContent="flex-end">
        <Skeleton variant="rounded" width="143px" height="24px" />
      </Grid>
    </Grid>
  );
}

export default BeneficiaryRequestDetailSkeleton;
