import { createReducer } from '@reduxjs/toolkit';

import UserMaintainerTypes from './userMaintainer.types';

const initialState = {
  loaders: {
    initial: false,
    filters: false,
  },
  controls: {
    error: false,
    userId: '',
    name: '',
  },
  pagination: {
    page: 0,
    pageSize: 10,
    total: 0,
  },
  users: [],
};

const UserMaintainerReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(UserMaintainerTypes.FETCH_USERS, (state) => {
      state.loaders.initial = true;
      state.controls.error = false;
    })
    .addCase(UserMaintainerTypes.FETCH_USERS_FAIL, (state) => {
      state.loaders.initial = false;
      state.controls.error = true;
    })
    .addCase(UserMaintainerTypes.FETCH_USERS_SUCCESS, (state, action) => {
      state.loaders.initial = false;
      state.users = action.payload.users;
      state.pagination.total = action.payload.total;
    })
    .addCase(UserMaintainerTypes.SET_PAGINATION, (state, action) => {
      state.pagination[action.payload.name] = action.payload.value;
    })
    .addCase(UserMaintainerTypes.SET_CONTROL, (state, action) => {
      state.controls[action.payload.name] = action.payload.value;
    })
    .addCase(UserMaintainerTypes.CLEAR_FILTERS, (state) => {
      state.controls = {
        ...state.controls,
        userId: '',
        name: '',
      };
    })
    .addCase(UserMaintainerTypes.UPDATE_USER, (state, action) => {
      const userIndex = state.users.findIndex((user) => user.id === action.payload.id);
      state.users[userIndex] = {
        ...state.users[userIndex],
        ...action,
      };
    })
    .addCase(UserMaintainerTypes.UPDATE_USER_FAIL, (state) => {
      state.controls.error = true;
    })
    .addCase(UserMaintainerTypes.UPDATE_USER_SUCCESS, (state) => {
      state.controls.error = false;
    });
});

export default UserMaintainerReducer;
