import React, { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Container, Grid, Stack, Typography, Link } from '@mui/material';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';

/* Project */
import { downloadFile } from 'utils/functions';
import sectionStyles from './section.styles';

const DocumentSection = forwardRef((props, ref) => {
  const dataLink = [
    {
      id: 1,
      url: '/#',
      name: 'Resumen de la Conciliación',
      filename: '01 Resumen de conciliación.pdf',
    },
    {
      id: 4,
      url: '/#',
      name: 'Términos y condiciones del sitio web',
      filename: '07 Términos y Condiciones de Uso Conciliación Carrier.pdf',
    },
    {
      id: 2,
      url: '/#',
      name: 'Acuerdo de Conciliación',
      filename: '02 Acuerdo de conciliación.pdf',
    },
    {
      id: 5,
      url: '/#',
      name: 'Listado de modelos de vehículos elegibles',
      filename: '05 Listado de vehículos elegibles.pdf',
    },
    {
      id: 3,
      url: '/#',
      name: 'Resolución aprobatoria',
      filename: '03 Resolución aprobatoria.pdf',
    },
    {
      id: 6,
      url: '/#',
      name: 'Política de Protección de datos personales',
      filename: '06 Política de Protección de Datos Conciliación Carrier.pdf',
    },
    {
      id: 7,
      url: '/#',
      name: 'Certificado de ejecutoriedad de la resolución aprobatoria',
      filename: '04 Certificado de ejecutoriedad.pdf',
    },
  ];

  return (
    <Container sx={sectionStyles.container} ref={ref}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom sx={sectionStyles.title}>
            Documentos
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
            {dataLink.map((item) => (
              <Link
                key={item.id}
                component={RouterLink}
                to="/"
                sx={sectionStyles.link}
                onClick={(event) => downloadFile(event, item.filename)}>
                <AttachFileRoundedIcon sx={{ paddingRight: 1, transform: 'rotate(45deg)' }} />
                {item.name}
              </Link>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
});

export default DocumentSection;
