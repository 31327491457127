import { createAction } from '@reduxjs/toolkit';
import OperatorRequestDetailTypes from 'content/features/operator-request-detail/operatorRequestDetail.types';

export const fetchOperatorRequestDetail = createAction(
  OperatorRequestDetailTypes.FETCH_REQUEST,
  (payload) => ({
    payload: {
      ...payload,
    },
  }),
);

export const fetchOperatorRequestDetailFail = createAction(
  OperatorRequestDetailTypes.FETCH_REQUEST_FAIL,
);

export const fetchOperatorRequestDetailSuccess = createAction(
  OperatorRequestDetailTypes.FETCH_REQUEST_SUCCESS,
);

export const setControl = createAction(OperatorRequestDetailTypes.SET_CONTROL, (payload) => ({
  payload: {
    ...payload,
  },
}));
export const toggleCheck = createAction(OperatorRequestDetailTypes.TOGGLE_CHECK, (payload) => ({
  payload: {
    ...payload,
  },
}));

export const acceptRequest = createAction(OperatorRequestDetailTypes.ACCEPT_REQUEST);

export const acceptRequestFail = createAction(OperatorRequestDetailTypes.ACCEPT_REQUEST_FAIL);

export const acceptRequestSuccess = createAction(OperatorRequestDetailTypes.ACCEPT_REQUEST_SUCCESS);

export const objectRequest = createAction(OperatorRequestDetailTypes.OBJECT_REQUEST);

export const objectRequestFail = createAction(OperatorRequestDetailTypes.OBJECT_REQUEST_FAIL);

export const objectRequestSuccess = createAction(OperatorRequestDetailTypes.OBJECT_REQUEST_SUCCESS);

export const rejectRequest = createAction(OperatorRequestDetailTypes.REJECT_REQUEST);

export const rejectRequestFail = createAction(OperatorRequestDetailTypes.REJECT_REQUEST_FAIL);

export const rejectRequestSuccess = createAction(OperatorRequestDetailTypes.REJECT_REQUEST_SUCCESS);

export const payRequest = createAction(OperatorRequestDetailTypes.PAY_REQUEST);

export const payRequestFail = createAction(OperatorRequestDetailTypes.PAY_REQUEST_FAIL);

export const payRequestSuccess = createAction(OperatorRequestDetailTypes.PAY_REQUEST_SUCCESS);

export const clearScreenData = createAction(OperatorRequestDetailTypes.CLEAR_SCREEN_DATA);

const OperatorRequestDetailActions = {
  fetchOperatorRequestDetail,
  fetchOperatorRequestDetailFail,
  fetchOperatorRequestDetailSuccess,
  setControl,
  toggleCheck,
  clearScreenData,
  acceptRequest,
  acceptRequestFail,
  acceptRequestSuccess,
  objectRequest,
  objectRequestFail,
  objectRequestSuccess,
  rejectRequest,
  rejectRequestFail,
  rejectRequestSuccess,
  payRequest,
  payRequestFail,
  payRequestSuccess,
};

export default OperatorRequestDetailActions;
