const OperatorRequestsTypes = {
  FETCH_REQUESTS: '[OperatorRequests] FETCH_REQUESTS',
  FETCH_REQUESTS_SUCCESS: '[OperatorRequests] FETCH_REQUESTS_SUCCESS',
  FETCH_REQUESTS_FAIL: '[OperatorRequests] FETCH_REQUESTS_FAIL',
  SET_PAGINATION: '[OperatorRequests] SET_PAGINATION',
  REVEAL_ROW_DATA: '[OperatorRequests] REVEAL_ROW_DATA',
  REVEAL_ROW_DATA_SUCCESS: '[OperatorRequests] REVEAL_ROW_DATA_SUCCESS',
  REVEAL_ROW_DATA_FAIL: '[OperatorRequests] REVEAL_ROW_DATA_FAIL',
  SET_CONTROL: '[OperatorRequests] SET_CONTROL',
  CREATE_REQUEST_CHECKBOXES_CONTROLS: '[OperatorRequests] CREATE_REQUEST_CHECKBOXES_CONTROLS',
  TOGGLE_INDIVIDUAL_CHECK: '[OperatorRequests] TOGGLE_INDIVIDUAL_CHECK',
  TOGGLE_MASTER_CHECK: '[OperatorRequests] TOGGLE_MASTER_CHECK',
  HIDE_ROW_DATA: '[OperatorRequests] HIDE_ROW_DATA',
  HIDE_ROW_DATA_SUCCESS: '[OperatorRequests] HIDE_ROW_DATA_SUCCESS',
  HIDE_ROW_DATA_FAIL: '[OperatorRequests] HIDE_ROW_DATA_FAIL',
  ASSIGN_REQUEST: '[OperatorRequests] ASSIGN_REQUEST',
  ASSIGN_REQUEST_SUCCESS: '[OperatorRequests] ASSIGN_REQUEST_SUCCESS',
  ASSIGN_REQUEST_FAIL: '[OperatorRequests] ASSIGN_REQUEST_FAIL',
  GET_INITIAL_DATA: '[OperatorRequests] GET_INITIAL_DATA',
  GET_INITIAL_DATA_SUCCESS: '[OperatorRequests] GET_INITIAL_DATA_SUCCESS',
  GET_INITIAL_DATA_FAIL: '[OperatorRequests] GET_INITIAL_DATA_FAIL',
  CLEAR_FILTERS: '[OperatorRequests] CLEAR_FILTERS',
  GET_CLIENT_REPORT: '[OperatorRequests] GET_CLIENT_REPORT',
  GET_CLIENT_REPORT_SUCCESS: '[OperatorRequests] GET_CLIENT_REPORT_SUCCESS',
  GET_CLIENT_REPORT_FAIL: '[OperatorRequests] GET_CLIENT_REPORT_FAIL',
  GET_TABLE_REPORT: '[OperatorRequests] GET_TABLE_REPORT',
  GET_TABLE_REPORT_SUCCESS: '[OperatorRequests] GET_TABLE_REPORT_SUCCESS',
  GET_TABLE_REPORT_FAIL: '[OperatorRequests] GET_TABLE_REPORT_FAIL',
};
export default OperatorRequestsTypes;
