import { createAction } from '@reduxjs/toolkit';

import LoginTypes from './login.types';

export const disableErrors = createAction(LoginTypes.DISABLE_ERRORS);

export const login = createAction(LoginTypes.LOGIN_USER);

export const loginSuccess = createAction(LoginTypes.LOGIN_USER_SUCCESS);

export const loginFail = createAction(LoginTypes.LOGIN_USER_FAIL);

export const controlsChanged = createAction(LoginTypes.CONTROLS_CHANGED, ({ name, value }) => ({
  payload: {
    [name]: value,
  },
}));

export const logout = createAction(LoginTypes.LOGOUT);

export const logoutSuccess = createAction(LoginTypes.LOGOUT_SUCCESS);

export const googleLogin = createAction(LoginTypes.GOOGLE_LOGIN);

export const googleLoginSuccess = createAction(LoginTypes.GOOGLE_LOGIN_SUCCESS);

export const googleLoginFail = createAction(LoginTypes.GOOGLE_LOGIN_FAIL);

export const resetControls = createAction(LoginTypes.RESET_CONTROLS);

export const samlLogin = createAction(LoginTypes.SAML_LOGIN, (payload) => ({
  payload,
}));

export const samlLoginSuccess = createAction(LoginTypes.SAML_LOGIN_SUCCESS, (payload) => ({
  payload,
}));
export const verify2FA = createAction('VERIFY_2FA', (payload) => ({
  payload,
}));

export const verify2FASuccess = createAction('VERIFY_2FA_SUCCESS', (payload) => ({
  payload,
}));

const LoginActions = {
  disableErrors,
  login,
  loginSuccess,
  loginFail,
  controlsChanged,
  logout,
  logoutSuccess,
  googleLogin,
  googleLoginSuccess,
  googleLoginFail,
  resetControls,
  samlLogin,
  samlLoginSuccess,
  verify2FA,
  verify2FASuccess,
};

export default LoginActions;
