import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import ColorCodedChip from 'content/shared/ColorCodedChip/ColorCodedChip';
import { Checkbox, IconButton, Tooltip, Typography } from '@mui/material';
import { FindInPage, Visibility, VisibilityOff } from '@mui/icons-material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  hideRowData,
  revealRowData,
  setControl,
  toggleIndividualCheck,
  toggleMasterCheck,
} from 'content/features/operator-requests/operatorRequests.actions';
import { useNavigate } from 'react-router-dom';

function StyledTableCell({ text, sx = {}, align = 'left' }) {
  return (
    <TableCell
      align={align}
      sx={{
        backgroundColor: 'black',
        color: 'white',
        pt: 2,
        pb: 2,
        fontWeight: 'semibold',
        ...sx,
      }}>
      {text}
    </TableCell>
  );
}

// eslint-disable-next-line complexity
function OperatorDesktopTable({ rows }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { individualChecks, checksError } = useSelector((state) => state.operatorRequests.controls);
  const { loaders } = useSelector((state) => state.operatorRequests);

  const handleRevealOrHideRowData = (id, revealed) => {
    if (!revealed) {
      dispatch(revealRowData({ id }));
      return;
    }
    dispatch(hideRowData({ id }));
  };

  const handleChangeMainCheckbox = (e) => {
    dispatch(toggleMasterCheck(e.target.checked));
    dispatch(setControl({ name: 'checksError', value: false }));
  };

  const handleChangeRowCheckbox = (id) => {
    dispatch(toggleIndividualCheck({ name: id, value: !individualChecks[id] }));
    dispatch(setControl({ name: 'checksError', value: false }));
  };

  const individualChecksArray = Object.values(individualChecks) || [];

  return (
    <TableContainer>
      <Table aria-label="request table">
        <TableHead>
          <TableRow>
            <StyledTableCell
              sx={{ borderTopLeftRadius: 8, pr: 0 }}
              text={
                <Checkbox
                  checked={
                    individualChecksArray.length
                      ? individualChecksArray.values(individualChecks).every((value) => value)
                      : false
                  }
                  indeterminate={
                    !Object.values(individualChecks).every((value) => value) &&
                    Object.values(individualChecks).some((value) => value)
                  }
                  sx={{
                    color: 'white',
                    '&.Mui-checked': {
                      color: 'white',
                    },
                    '&.MuiCheckbox-indeterminate': {
                      color: 'white',
                    },
                  }}
                  onChange={handleChangeMainCheckbox}
                />
              }
            />
            <StyledTableCell sx={{ pl: 0, pr: 0 }} text="ID de solicitud" />
            <StyledTableCell sx={{ pl: 0, pr: 0 }} text="Fecha de solicitud" />
            <StyledTableCell sx={{ pl: 0, pr: 0 }} text="Vehículo" />
            <StyledTableCell sx={{ pl: 0, pr: 0 }} text="RUN" />
            <StyledTableCell sx={{ pl: 0, pr: 0 }} text="Patente" />
            <StyledTableCell sx={{ pl: 0, pr: 0 }} text="Tipo de solicitud" />
            <StyledTableCell sx={{ pl: 0, pr: 0 }} text="Operador" />
            <StyledTableCell sx={{ pl: 0, pr: 0 }} text="Estado" align="center" />
            <StyledTableCell
              align="center"
              sx={{ borderTopRightRadius: 8, pl: 0 }}
              text="Acciones"
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 && (
            <TableRow>
              <TableCell colSpan={9}>
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  No hay solicitudes
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {/* eslint-disable-next-line complexity */}
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}>
              <TableCell align="left" sx={{ pr: 0, pt: 0.8, pb: 0.8 }}>
                <Checkbox
                  checked={individualChecks[row.id] || false}
                  disabled={row.operatorRequestName || false}
                  onChange={() => handleChangeRowCheckbox(row.id)}
                  sx={{ color: checksError ? '#DA291C' : '#00000099' }}
                />
              </TableCell>
              <TableCell align="left" sx={{ p: 0 }}>
                {row.id}
              </TableCell>
              <TableCell align="left" sx={{ p: 0 }}>
                {new Date(row.createdAt).toLocaleDateString('es-CL')}
              </TableCell>
              <TableCell align="left" sx={{ p: 0 }}>
                {row.vehicleData}
              </TableCell>
              <TableCell align="left" sx={{ pl: 0, pr: 2 }}>
                {row.run}
              </TableCell>
              <TableCell align="left" sx={{ pl: 0, pr: 2 }}>
                {row.vehicleLicensePlate}
              </TableCell>
              <TableCell align="left" sx={{ p: 0 }}>
                {row.requestType}
              </TableCell>
              <TableCell align="left" sx={{ p: 0 }}>
                {row.operatorRequestName || 'Sin asignar'}
              </TableCell>
              <TableCell align="center" sx={{ p: 0 }}>
                <ColorCodedChip state={row.requestStatus} />
              </TableCell>
              <TableCell align="center" sx={{ pl: 0, pt: 0.8, pb: 0.8 }}>
                <IconButton
                  aria-label="see request"
                  color="primary"
                  onClick={() => navigate(`/operator-requests/${row.id}`)}>
                  <Tooltip title="Ver solicitud" arrow>
                    <FindInPage />
                  </Tooltip>
                </IconButton>
                <IconButton
                  aria-label="see values"
                  color="primary"
                  disabled={loaders.reveal || loaders.hide}
                  onClick={() => handleRevealOrHideRowData(row.id, row.revealed)}>
                  <Tooltip title={row.revealed ? 'Ocultar datos' : 'Mostrar datos'} arrow>
                    {row.revealed ? <VisibilityOff /> : <Visibility />}
                  </Tooltip>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default OperatorDesktopTable;
