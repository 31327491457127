const UserMaintainerTypes = {
  FETCH_USERS: '[UserMaintainer] FETCH_USERS',
  FETCH_USERS_SUCCESS: '[UserMaintainer] FETCH_USERS_SUCCESS',
  FETCH_USERS_FAIL: '[UserMaintainer] FETCH_USERS_FAIL',
  SET_PAGINATION: '[UserMaintainer] SET_PAGINATION',
  SET_CONTROL: '[UserMaintainer] SET_CONTROL',
  CLEAR_FILTERS: '[UserMaintainer] CLEAR_FILTERS',
  UPDATE_USER: '[UserMaintainer] UPDATE_USER',
  UPDATE_USER_SUCCESS: '[UserMaintainer] UPDATE_USER_SUCCESS',
  UPDATE_USER_FAIL: '[UserMaintainer] UPDATE_USER_FAIL',
  RECOVER_USER_PWD: '[UserMaintainer] RECOVER_USER_PWD',
  RECOVER_USER_PWD_SUCCESS: '[UserMaintainer] RECOVER_USER_PWD_SUCCESS',
  RECOVER_USER_PWD_FAIL: '[UserMaintainer] RECOVER_USER_PWD_FAIL',
};

export default UserMaintainerTypes;
