import React, { useCallback, useEffect, useState } from 'react';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* React Router */
import { useNavigate } from 'react-router-dom';

/* Project */
import { LoginForm, LoginSelector, LoginSnackbars } from 'content/features/authentication';
import { validateEmail, validatePassword } from 'utils/functions';
import {
  controlsChanged,
  googleLogin,
  login,
  resetControls,
} from '../../features/authentication/login/login.actions';
import { toggleErrorMessage, toggleSuccessMessage } from '../../app.actions';

const { page: loginSelector } = LoginSelector;

function Login() {
  const [errors, setErrors] = useState({ email: {}, password: {} });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const states = useSelector(loginSelector);
  const displayLogin = useSelector((state) => state.app.displayLogin);

  const { controls, error, errorMsg, success, successMsg, loading, loginError, message } = states;
  const { email, password } = controls;

  useEffect(
    () => () => {
      dispatch(resetControls());
    },
    [dispatch],
  );

  useEffect(() => {
    if (!displayLogin) {
      navigate('/');
    }
  }, []);

  const onChangeText = useCallback(({ target: { id, value } }) => {
    dispatch(controlsChanged({ name: id, value }));
  }, []);

  const toggleError = () => dispatch(toggleErrorMessage());
  const toggleSuccess = () => dispatch(toggleSuccessMessage());

  const goToHome = () => navigate('/');

  const validateFields = () => {
    const currentErrors = {
      email: validateEmail(email),
      password: validatePassword(password),
    };

    setErrors(currentErrors);

    return currentErrors;
  };
  const handleLogin = () => {
    const validAuth = () => Object.values(validateFields()).every((fieldError) => fieldError.check);

    if (validAuth()) {
      dispatch(login({ email, password }));
    }
  };

  const handleCallbackResponse = (response) => {
    dispatch(googleLogin({ token: response.credential }));
  };

  const content = (
    <LoginForm
      handleLogin={handleLogin}
      handleCallbackResponse={handleCallbackResponse}
      controls={controls}
      errors={errors}
      onChangeText={onChangeText}
      loading={loading}
      goToHome={goToHome}
      error={loginError}
      message={message}
    />
  );

  const snackbars = (
    <LoginSnackbars
      toggleError={toggleError}
      toggleSuccess={toggleSuccess}
      error={error}
      errorMsg={errorMsg}
      success={success}
      successMsg={successMsg}
    />
  );

  return (
    <>
      {content}
      {snackbars}
    </>
  );
}

export default Login;
