import React from 'react';

/* React Dom */
import ReactDOM from 'react-dom/client';

/* React Router */
import { BrowserRouter } from 'react-router-dom';

/* React Redux */
import { Provider } from 'react-redux';

/* Redux Persist */
// import { PersistGate } from 'redux-persist/integration/react';
// import { persistStore } from 'redux-persist';

/* Sentry */
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

/* Extra */
import 'fontsource-roboto';
import 'react-toastify/dist/ReactToastify.min.css';

/* Project */
import environments from 'config/environments';
import App from './content/App';
import SentryFallback from './content/shared/SentryFallback/SentryFallback';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import './service-worker';

import defaultStore from './config/store';
import reportWebVitals from './reportWebVitals';
import './styles/main.scss';
import './styles/styles.scss';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready
    .then((registration) => {
      registration.unregister();
    })
    .catch((error) => {
      console.error(error.message);
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));

Sentry.init({
  dsn: environments.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: environments.TRACES_SAMPLE_RATE,
});

const sentryFallback = ({ error, componentStack, resetError }) => (
  <SentryFallback error={error} componentStack={componentStack} resetError={resetError} />
);

// const persistor = persistStore(defaultStore);

root.render(
  <Sentry.ErrorBoundary fallback={sentryFallback} showDialog>
    <Provider store={defaultStore}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </Sentry.ErrorBoundary>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
