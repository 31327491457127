import React from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import CustomDropzone from 'content/features/beneficiary-request/components/CustomDropzone';

const ErrorTextStyle = {
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '19.92px',
  letterSpacing: '0.4000000059604645px',
  textAlign: 'left',
  color: '#DA291C',
};
function DropzoneHeader({ text }) {
  return (
    <Typography variant="body2" sx={{ mb: 1, fontFamily: 'Open Sans' }}>
      {text}
    </Typography>
  );
}

function MobileVersion({ onChange, errors, controls }) {
  const renderDropzone = (dropId, text, preLoadedFile) => (
    <Grid item xs={12} sx={{ mb: errors[dropId].length ? 2 : 0 }}>
      <DropzoneHeader text={text} />
      <CustomDropzone
        onChange={onChange}
        errors={errors}
        dropId={dropId}
        preLoadedFile={preLoadedFile}
      />
      {errors[dropId] && <Typography sx={{ ...ErrorTextStyle }}>{errors[dropId][0]}</Typography>}
    </Grid>
  );

  return (
    <Grid container item xs={12} spacing={8}>
      {renderDropzone(
        'frontIdentityDocument',
        'Adjuntar cara delantera de cédula de identidad del solicitante',
        controls?.frontIdCard,
      )}
      {renderDropzone(
        'backIdentityDocument',
        'Adjuntar cara trasera de cédula de identidad del solicitante',
        controls?.backIdCard,
      )}
      {renderDropzone(
        'carInscriptionDocument',
        'Adjuntar copia de certificado de inscripción y anotaciones vigentes del vehículo',
        controls?.carInscription,
      )}
    </Grid>
  );
}

function DesktopVersion({ onChange, errors, controls }) {
  const renderDropzone = (dropId, text, mb, preLoadedFile) => (
    <Grid item xs={12} sx={{ mb }}>
      <DropzoneHeader text={text} />
      <CustomDropzone
        onChange={onChange}
        errors={errors}
        dropId={dropId}
        preLoadedFile={preLoadedFile}
      />
      {errors[dropId] && <Typography sx={{ ...ErrorTextStyle }}>{errors[dropId][0]}</Typography>}
    </Grid>
  );

  return (
    <Grid container item xs={12} spacing={4}>
      <Grid item container md={6} spacing={6}>
        {renderDropzone(
          'frontIdentityDocument',
          'Adjuntar cara delantera de cédula de identidad del solicitante (jpg, jpeg, jfif, pjpeg, pjp, png, pdf)',
          2,
          controls?.frontIdCard,
        )}
        {renderDropzone(
          'backIdentityDocument',
          'Adjuntar cara trasera de cédula de identidad del solicitante (jpg, jpeg, jfif, pjpeg, pjp, png, pdf)',
          0,
          controls?.backIdCard,
        )}
      </Grid>
      <Grid item container md={6}>
        {renderDropzone(
          'carInscriptionDocument',
          'Adjuntar copia de certificado de inscripción y anotaciones vigentes del vehículo (jpg, jpeg, jfif, pjpeg, pjp, png, pdf)',
          0,
          controls?.carInscription,
        )}
      </Grid>
    </Grid>
  );
}

function PersonalDocumentsForm({ onChange, errors, controls }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return isMobile ? (
    <MobileVersion onChange={onChange} errors={errors} controls={controls} />
  ) : (
    <DesktopVersion onChange={onChange} errors={errors} controls={controls} />
  );
}

export default PersonalDocumentsForm;
