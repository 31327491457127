import { createReducer } from '@reduxjs/toolkit';

import BeneficiaryRequestDetailTypes from 'content/features/beneficiary-request-detail/beneficiaryRequestDetail.types';

const initialState = {
  loaders: {
    initial: false,
  },
  controls: {
    error: false,
  },
  request: {
    id: '',
    // vehicle section
    vehicleVin: '',
    vehicleBrand: '',
    vehicleModel: '',
    vehicleYear: '',
    vehicleLicensePlate: '',
    // beneficiary section
    personTaxNumber: '',
    personNames: '',
    personPaternalSurname: '',
    personMaternalSurname: '',
    personAddress: '',
    personRegion: '',
    personCity: '',
    personCommune: '',
    personPhone: '',
    personEmail: '',
    // payment method section
    accountPaymentMethod: '',
    accountHolderName: '',
    accountHolderTaxNumber: '',
    accountBank: '',
    accountType: '',
    accountNumber: '',
    // personal documents section
    frontIdCard: {
      src: '',
      type: '',
    },
    backIdCard: {
      src: '',
      type: '',
    },
    carInscription: {
      src: '',
      type: '',
    },
    // request status section
    requestStatus: '',
  },
};

const BeneficiaryRequestDetailReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(BeneficiaryRequestDetailTypes.FETCH_REQUEST, (state) => {
      state.loaders.initial = true;
      state.controls.error = false;
    })
    .addCase(BeneficiaryRequestDetailTypes.FETCH_REQUEST_FAIL, (state) => {
      state.loaders.initial = false;
      state.controls.error = true;
    })
    .addCase(BeneficiaryRequestDetailTypes.FETCH_REQUEST_SUCCESS, (state, action) => {
      state.loaders.initial = false;
      state.request = action.payload;
    });
});

export default BeneficiaryRequestDetailReducer;
