/* Material UI */
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import DvrIcon from '@mui/icons-material/Dvr';
import { AccountCircle } from '@mui/icons-material';
/* Router */
import Home from 'content/pages/Home/Home';
import Login from 'content/pages/Login/Login';
import MFA from 'content/pages/MFA/MFA';
import RegisterUser from 'content/pages/Register/Register';
import RecoveryPassword from 'content/pages/Recovery/RecoveryPassword';
import UserMaintainer from 'content/pages/UserMaintainer/UserMaintainer';
import UserMaintainerAdmin from 'content/pages/UserMaintainer/UserMaintainerAdmin';
import BeneficiaryRequests from 'content/pages/BeneficiaryRequests/BeneficiaryRequests';

import BeneficiaryRequest from 'content/pages/BeneficiaryRequest/BeneficiaryRequest';
import BeneficiaryRequestDetail from 'content/pages/BeneficiaryRequestDetail/BeneficiaryRequestDetail';
import OperatorRequests from 'content/pages/OperatorRequests/OperatorRequests';
import OperatorRequestDetail from 'content/pages/OperatorRequestDetail/OperatorRequestDetail';
import BeneficiaryAmendment from 'content/pages/BeneficiaryAmendment/BeneficiaryAmendment';
import TermsAndConditions from 'content/pages/TermsAndConditions/TermsAndConditions';
import {
  ALL as allRoles,
  BENEFICIARY as beneficiaryRole,
  OPERATOR as operatorRole,
  ADMIN as adminRole,
} from './roles';

const Paths = {
  publics: [
    {
      path: '/',
      component: Home,
      useLayout: true,
      allowedRoles: [...allRoles],
    },
    {
      path: '/recovery/*',
      component: RecoveryPassword,
      useLayout: false,
      isAuth: true,
      allowedRoles: [...allRoles],
    },
    {
      path: '/login',
      component: Login,
      useLayout: false,
      isAuth: true,
      allowedRoles: [...allRoles],
    },
    {
      path: '/login/mfa',
      component: MFA,
      useLayout: false,
      isAuth: true,
      allowedRoles: [...allRoles],
    },
    {
      path: '/register',
      component: RegisterUser,
      useLayout: false,
      isAuth: true,
      allowedRoles: [...allRoles],
    },
  ],
  shared: [],
  protecteds: [
    {
      path: '/requests',
      name: 'Solicitudes',
      component: BeneficiaryRequests,
      Icon: DvrIcon,
      allowedRoles: [beneficiaryRole],
    },
    {
      path: '/user-maintainer',
      component: UserMaintainer,
      allowedRoles: [operatorRole],
      name: 'Mantenedor de Usuarios',
      Icon: AccountCircle,
    },
    {
      path: '/user-maintainer-admin',
      component: UserMaintainerAdmin,
      allowedRoles: [adminRole],
      name: 'Mantenedor de Usuarios',
      Icon: AccountCircle,
    },
    {
      path: '/requests/new',
      component: BeneficiaryRequest,
      allowedRoles: [beneficiaryRole],
    },
    {
      path: '/requests/:id',
      component: BeneficiaryRequestDetail,
      allowedRoles: [beneficiaryRole],
    },
    {
      path: '/operator-requests',
      name: 'Solicitudes',
      component: OperatorRequests,
      Icon: DvrIcon,
      allowedRoles: [operatorRole],
    },
    {
      path: '/operator-requests/:id',
      component: OperatorRequestDetail,
      allowedRoles: [operatorRole],
    },
    {
      path: '/amendment/:id',
      component: BeneficiaryAmendment,
      allowedRoles: [beneficiaryRole],
    },
    {
      path: '/terms-and-conditions',
      name: 'Términos y condiciones',
      component: TermsAndConditions,
      Icon: DescriptionRoundedIcon,
      allowedRoles: [beneficiaryRole, operatorRole],
    },
  ],
  independients: [],
};

export default Paths;
