import { createAction } from '@reduxjs/toolkit';

import BeneficiaryRequestsTypes from './beneficiaryRequests.types';

export const fetchBeneficiaryRequestsList = createAction(
  BeneficiaryRequestsTypes.FETCH_REQUESTS,
  (page, pageSize) => ({
    payload: {
      page,
      pageSize,
    },
  }),
);

export const fetchBeneficiaryRequestsListFail = createAction(
  BeneficiaryRequestsTypes.FETCH_REQUESTS_FAIL,
);

export const fetchBeneficiaryRequestsListSuccess = createAction(
  BeneficiaryRequestsTypes.FETCH_REQUESTS_SUCCESS,
);

export const setPagination = createAction(BeneficiaryRequestsTypes.SET_PAGINATION, (payload) => ({
  payload,
}));

const BeneficiaryRequestsActions = {
  fetchBeneficiaryRequestsList,
  fetchBeneficiaryRequestsListFail,
  fetchBeneficiaryRequestsListSuccess,
  setPagination,
};

export default BeneficiaryRequestsActions;
