import { Chip } from '@mui/material';
import React from 'react';

function ColorCodedChip({ state, sx = {} }) {
  const colorState = {
    Ingresada: '#005587',
    Objetada: '#ED8B00',
    Aceptada: '#26890D',
    Rechazada: '#DA291C',
    Pagada: '#26890D',
  };

  const styleChip = {
    backgroundColor: colorState[state],
    color: '#ffffff',
    minWidth: '90px',
    ...sx,
  };

  return <Chip label={state} sx={styleChip} />;
}

export default ColorCodedChip;
