import { createReducer } from '@reduxjs/toolkit';

/* Extra */
import BeneficiaryRequestTypes from 'content/features/beneficiary-request/beneficiaryRequest.types';
import { formatDateForCalendar } from 'utils/helper';

const initialState = {
  loaders: {
    initial: false,
    create: false,
    requestCreated: false,
  },
  errorControls: {
    initialError: false,
    createError: false,
  },
  controls: {
    vin: '',
    licensePlate: '',
    brand: '',
    model: '',
    year: '',
    rut: '',
    names: '',
    paternalSurname: '',
    maternalSurname: '',
    address: '',
    region: '',
    city: '',
    commune: '',
    phone: '',
    email: '',
    accountHolderName: '',
    accountHolderRut: '',
    accountType: '',
    accountHolderNumber: '',
    accountBank: '',
    accountPaymentMethod: '',
    frontIdentityDocument: null,
    backIdentityDocument: null,
    carInscriptionDocument: null,
    termsAndConditions: false,
    signature: '',
    signatureDate: formatDateForCalendar(new Date()),
  },
  initialData: {
    vehicleBrands: [],
    vehicleModels: [],
    vehicleYears: [],
    personRegion: [],
    personCity: [],
    personCommune: [],
    accountBanks: [],
    accountTypes: [],
    accountPaymentMethods: [],
  },
};

const BeneficiaryRequestReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(BeneficiaryRequestTypes.FETCH_FORM_INITIAL_DATA, (state) => {
      state.loaders.initial = true;
      state.loaders.requestCreated = false;
      state.loaders.create = false;
      state.initialData.vehicleBrands = [];
      state.initialData.vehicleModels = [];
      state.initialData.personRegion = [];
      state.initialData.personCity = [];
      state.initialData.personCommune = [];
      state.initialData.accountBanks = [];
      state.initialData.accountTypes = [];
      state.initialData.accountPaymentMethods = [];
      state.initialData.vehicleYears = [];
      state.errorControls.initialError = false;
      state.controls = initialState.controls;
    })
    .addCase(BeneficiaryRequestTypes.FETCH_FORM_INITIAL_DATA_FAIL, (state) => {
      state.loaders.initial = false;
      state.errorControls.initialError = true;
    })
    .addCase(BeneficiaryRequestTypes.FETCH_FORM_INITIAL_DATA_SUCCESS, (state, action) => {
      state.loaders.initial = false;
      state.initialData.vehicleBrands = action.payload.data.vehicleBrand.sort((a, b) =>
        a.label.localeCompare(b.label),
      );
      state.initialData.vehicleModels = action.payload.data.vehicleModel.sort((a, b) =>
        a.label.localeCompare(b.label),
      );
      state.initialData.personRegion = action.payload.data.personRegion.sort((a, b) =>
        a.label.localeCompare(b.label),
      );
      state.initialData.personCity = action.payload.data.personCity.sort((a, b) =>
        a.label.localeCompare(b.label),
      );
      state.initialData.personCommune = action.payload.data.personCommune.sort((a, b) =>
        a.label.localeCompare(b.label),
      );
      state.initialData.accountBanks = action.payload.data.accountBank;
      state.initialData.accountTypes = action.payload.data.accountType;
      state.initialData.accountPaymentMethods = action.payload.data.accountPaymentMethod;
      state.initialData.vehicleYears = action.payload.data.vehicleYear.sort((a, b) => a - b);
    })
    .addCase(BeneficiaryRequestTypes.CREATE_BENEFICIARY_REQUEST, (state) => {
      state.loaders.create = true;
      state.errorControls.createError = false;
      state.loaders.requestCreated = false;
    })
    .addCase(BeneficiaryRequestTypes.CREATE_BENEFICIARY_REQUEST_FAIL, (state) => {
      state.loaders.create = false;
      state.errorControls.createError = true;
      state.loaders.requestCreated = false;
    })
    .addCase(BeneficiaryRequestTypes.CREATE_BENEFICIARY_REQUEST_SUCCESS, (state) => {
      state.loaders.create = false;
      state.loaders.requestCreated = true;
    })
    .addCase(BeneficiaryRequestTypes.SET_CONTROL, (state, action) => {
      state.controls[action.payload.name] = action.payload.value;
    })
    .addCase(BeneficiaryRequestTypes.CLEAR_SCREEN_DATA, (state) => {
      state.controls = initialState.controls;
      state.loaders = initialState.loaders;
      state.errorControls = initialState.errorControls;
      state.initialData = initialState.initialData;
    });
});

export default BeneficiaryRequestReducer;
