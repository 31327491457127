import React, { useMemo } from 'react';
import { Grid, MenuItem, TextField } from '@mui/material';

function BeneficiaryForm({ errors, onChange, controls, initialData, amendment }) {
  const { personRegion, personCity, personCommune } = initialData;

  const {
    rut,
    names,
    paternalSurname,
    maternalSurname,
    address,
    region,
    city,
    commune,
    phone,
    email,
  } = controls;

  const citiesFiltered = useMemo(
    () => personCity.filter((pCity) => pCity.regionId === region),
    [region, personCity],
  );

  const communesFiltered = useMemo(
    () => personCommune.filter((pCommune) => pCommune.cityId === city),
    [city, personCommune],
  );

  return (
    <>
      <Grid item md={3} xs={12}>
        <TextField
          id="rut"
          name="rut"
          label="RUT"
          fullWidth
          variant="outlined"
          disabled={amendment}
          error={!!errors.rut[0]}
          helperText={errors.rut[0]}
          onChange={onChange}
          value={rut}
          autoComplete="off"
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          id="names"
          name="names"
          label="Nombres"
          fullWidth
          variant="outlined"
          error={!!errors.names[0]}
          helperText={errors.names[0]}
          onChange={onChange}
          value={names}
          autoComplete="off"
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          id="paternalSurname"
          name="paternalSurname"
          fullWidth
          label="Apellido paterno"
          variant="outlined"
          error={!!errors.paternalSurname[0]}
          helperText={errors.paternalSurname[0]}
          onChange={onChange}
          value={paternalSurname}
          autoComplete="off"
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          id="maternalSurname"
          name="maternalSurname"
          fullWidth
          label="Apellido materno"
          variant="outlined"
          error={!!errors.maternalSurname[0]}
          helperText={errors.maternalSurname[0]}
          onChange={onChange}
          value={maternalSurname}
          autoComplete="off"
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          id="address"
          name="address"
          fullWidth
          label="Dirección"
          variant="outlined"
          error={!!errors.address[0]}
          helperText={errors.address[0]}
          onChange={onChange}
          value={address}
          autoComplete="off"
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          id="region"
          name="region"
          fullWidth
          select
          label="Región"
          error={!!errors.region[0]}
          helperText={errors.region[0]}
          onChange={onChange}
          value={region}>
          {personRegion.map((pRegion) => (
            <MenuItem key={pRegion.value} value={pRegion.value}>
              {pRegion.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          id="city"
          name="city"
          fullWidth
          select
          label="Ciudad / Provincia"
          disabled={!region}
          error={!!errors.city[0]}
          helperText={errors.city[0]}
          onChange={onChange}
          value={city}>
          {citiesFiltered.map((pCity) => (
            <MenuItem key={pCity.value} value={pCity.value}>
              {pCity.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          id="commune"
          name="commune"
          fullWidth
          select
          label="Comuna"
          disabled={!city}
          error={!!errors.commune[0]}
          helperText={errors.commune[0]}
          onChange={onChange}
          value={commune}>
          {communesFiltered.map((pCommune) => (
            <MenuItem key={pCommune.value} value={pCommune.value}>
              {pCommune.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          id="phone"
          name="phone"
          fullWidth
          label="Teléfono"
          variant="outlined"
          error={!!errors.phone[0]}
          helperText={errors.phone[0]}
          onChange={onChange}
          value={phone}
          inputProps={{ maxLength: 11 }}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          id="email"
          name="email"
          fullWidth
          label="Correo electrónico"
          variant="outlined"
          error={!!errors.email[0]}
          helperText={errors.email[0]}
          onChange={onChange}
          value={email}
        />
      </Grid>
    </>
  );
}

export default BeneficiaryForm;
