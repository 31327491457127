import { createAction } from '@reduxjs/toolkit';
import BeneficiaryRequestTypes from 'content/features/beneficiary-request/beneficiaryRequest.types';

export const fetchFormInitialData = createAction(BeneficiaryRequestTypes.FETCH_FORM_INITIAL_DATA);

export const fetchFormInitialDataFail = createAction(
  BeneficiaryRequestTypes.FETCH_FORM_INITIAL_DATA_FAIL,
);

export const fetchFormInitialDataSuccess = createAction(
  BeneficiaryRequestTypes.FETCH_FORM_INITIAL_DATA_SUCCESS,
);

export const createBeneficiaryRequest = createAction(
  BeneficiaryRequestTypes.CREATE_BENEFICIARY_REQUEST,
);

export const createBeneficiaryRequestFail = createAction(
  BeneficiaryRequestTypes.CREATE_BENEFICIARY_REQUEST_FAIL,
);

export const createBeneficiaryRequestSuccess = createAction(
  BeneficiaryRequestTypes.CREATE_BENEFICIARY_REQUEST_SUCCESS,
);

export const setControl = createAction(BeneficiaryRequestTypes.SET_CONTROL, (payload) => ({
  payload: {
    ...payload,
  },
}));

export const clearScreenData = createAction(BeneficiaryRequestTypes.CLEAR_SCREEN_DATA);

const BeneficiaryRequestActions = {
  fetchFormInitialData,
  fetchFormInitialDataFail,
  fetchFormInitialDataSuccess,
  createBeneficiaryRequest,
  createBeneficiaryRequestFail,
  createBeneficiaryRequestSuccess,
  setControl,
  clearScreenData,
};

export default BeneficiaryRequestActions;
