import React from 'react';
import { Fab, Tooltip } from '@mui/material';
import { Add } from '@mui/icons-material';

function NewRequestButton({ onClick }) {
  return (
    <Tooltip title="Nueva solicitud" arrow>
      <Fab
        color="primary"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
        onClick={onClick}>
        <Add />
      </Fab>
    </Tooltip>
  );
}

export default NewRequestButton;
