/* eslint-disable no-case-declarations */
import React, { useMemo, useState } from 'react';

/* Extra */
import { Button, Grid, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from 'react-redux';

/* Project */
import { getItemInStorage } from 'utils/functions';
import ConfirmationDialog from 'content/shared/Dialogs/ConfirmationDialog';
import { recoverUserPwd, updateUser } from '../userMaintainer.actions';

function StyledTableCell({ text, sx = {}, align = 'left' }) {
  return (
    <TableCell
      align={align}
      sx={{
        backgroundColor: 'black',
        color: 'white',
        pt: 2,
        pb: 2,
        fontWeight: 'semibold',
        ...sx,
      }}>
      {text}
    </TableCell>
  );
}

// eslint-disable-next-line complexity
function UserTable({ rows = [] }) {
  const dispatch = useDispatch();
  const { pagination, controls } = useSelector((state) => state.userMaintainer);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogConfirmText, setDialogConfirmText] = useState('');
  const [dialogCancelText, setDialogCancelText] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogCallback, setDialogCallback] = useState(() => {});

  const user = getItemInStorage('user');

  const handleDisable = (userId, userName, userType, currentIsEnabled) => {
    setOpenDialog(false);
    const newIsEnabled = !currentIsEnabled;
    dispatch(
      updateUser({
        id: userId,
        name: userName,
        type: userType,
        isEnabled: newIsEnabled,
        controls,
        pagination,
      }),
    );
  };

  const handleRecoverPassword = (userId, email) => {
    setOpenDialog(false);
    dispatch(recoverUserPwd({ id: userId, email, controls, pagination }));
  };

  // eslint-disable-next-line complexity
  const setConfirmationDialog = (key, params) => {
    switch (key) {
      case 'recover_pwd':
        setDialogTitle('Recupear contraseña ');
        setDialogConfirmText('Confirmar');
        setDialogMessage(
          `¿Está seguro que desea enviar el correo de recuperación de contraseña al usuario: ${params.name}?`,
        );
        const recoverCallback = () => handleRecoverPassword(params.id, params.email);
        setDialogCallback(() => recoverCallback);
        setOpenDialog(true);
        break;
      case 'update_usr':
        setDialogTitle('Actualizar usuario');
        setDialogConfirmText('Confirmar');
        setDialogMessage(
          `¿Está seguro que desea ${params.isEnabled ? 'deshabilitar' : 'habilitar'} al usuario: ${
            params.name
          }?`,
        );
        const uptCallback = () =>
          handleDisable(params.id, params.name, params.type, params.isEnabled);
        setDialogCallback(() => uptCallback);
        setOpenDialog(true);
        break;
      default:
        setOpenDialog(false);
    }
  };

  const closeConfirmationDialog = () => {
    setDialogTitle('');
    setDialogConfirmText('');
    setDialogCancelText('');
    setDialogMessage('');
    setDialogCallback(() => {});
    setOpenDialog(false);
  };

  const sortedRows = useMemo(() => [...rows].sort((a, b) => a.name.localeCompare(b.name)), [rows]);

  return (
    <>
      <ConfirmationDialog
        open={openDialog}
        confirmText={dialogConfirmText}
        cancelText={dialogCancelText}
        title={dialogTitle}
        closeConfirmationModal={closeConfirmationDialog}
        handleSubmitConfirmation={dialogCallback}
        message={dialogMessage}
      />
      <TableContainer>
        <Table aria-label="request table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ borderTopLeftRadius: 8, pr: 0 }} text="ID de usuario" />
              <StyledTableCell sx={{ pl: 0, pr: 0 }} text="Nombre" />
              {user.userType === 'admin' && (
                <>
                  <StyledTableCell align="center" sx={{ pr: 0, pl: 0 }} text="Email" />
                  <StyledTableCell align="center" sx={{ pr: 0, pl: 0 }} text="Rol" />
                  <StyledTableCell
                    align="center"
                    sx={{ borderTopRightRadius: 8, pl: 0, pr: 0 }}
                    text="Acciones"
                  />
                </>
              )}
              {user.userType !== 'admin' && (
                <StyledTableCell
                  align="center"
                  sx={{ borderTopRightRadius: 8, pr: 0, pl: 0 }}
                  text="Email"
                />
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!sortedRows.length && (
              <TableRow>
                <TableCell colSpan={7}>
                  <Typography variant="body2" sx={{ textAlign: 'center' }}>
                    No hay usuarios
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {sortedRows.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}>
                <TableCell align="left" sx={{ pr: 0, pt: 3, pb: 3 }}>
                  {row.id}
                </TableCell>
                <TableCell align="left" sx={{ p: 0 }}>
                  {row.name}
                </TableCell>
                <TableCell align="center" sx={{ pl: 0, pt: 3, pb: 3 }}>
                  {row.email}
                </TableCell>
                {user.userType === 'admin' && (
                  <>
                    <TableCell align="center" sx={{ pl: 0, pt: 3, pb: 3 }}>
                      {row.type}
                    </TableCell>
                    <TableCell align="center" sx={{ pl: 0, pt: 3, pb: 3 }}>
                      <Grid container justifyContent="space-evenly">
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              // handleDisable(row.id, row.name, row.type, row.isEnabled)
                              setConfirmationDialog('update_usr', {
                                name: row.name,
                                id: row.id,
                                type: row.type,
                                isEnabled: row.isEnabled,
                              })
                            }>
                            {row.isEnabled ? 'Deshabilitar' : 'Habilitar'}
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="inherit"
                            onClick={() =>
                              // handleRecoverPassword(row.id, row.isEnabled)
                              setConfirmationDialog('recover_pwd', {
                                id: row.id,
                                name: row.name,
                                email: row.email,
                                type: row.type,
                                isEnabled: row.isEnabled,
                              })
                            }>
                            Recuperar contraseña
                          </Button>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default UserTable;
